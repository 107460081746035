'use strict';

export const getS3ImageName = (s3Partial, size) => {
  // https://spontlylive.s3-eu-west-1.amazonaws.com${listing.image}
  
  const index = s3Partial.lastIndexOf('.');

  return 'https://spontlylive.s3-eu-west-1.amazonaws.com' +
    `${s3Partial.substr(0, index)}-${size}` +
    s3Partial.substr(index);
}
