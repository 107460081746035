import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import TimeAgo from 'timeago-react';

// Bootstrap
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import Table from 'react-bootstrap/Table';

import { DropDownFilter } from '../../../lib/components/DropDownFilter';
import { Pagination } from '../../../lib/components/Pagination';
import { FlashDealStatusPill } from
  '../../myListings/components/FlashDealStatusPill';

import { loadFlashDealsList } from '../store/actions';
import { logOut } from '../../auth/store/actions';
import { selectFlashDeal } from '../store/slice';

import { useIsAdmin } from '../../../lib/hooks/useIsAdmin';
import { friendlyDate } from '../../../lib/util/dates';

export const ModerateFlashDealsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAdmin = useIsAdmin();

  const [showNoListings, setShowNoListings] = useState(false);

  const access = useSelector(({ auth }) => auth.access);
  const flashDeals = useSelector(({ moderate }) => moderate.flashDeals);
  const isLoading = useSelector(({ moderate }) => moderate.isLoading);
  const isLoggedIn = useSelector(({ auth }) => auth.isLoggedIn);
  // const appsForFilter = useSelector(({ enquiries }) => enquiries.appsForFilter);
  // const enquiries = useSelector(({ enquiries }) => enquiries.enquiries);
  // const numTotal = useSelector(({ enquiries }) => enquiries.numTotal);
  // const perPage = useSelector(({ enquiries }) => enquiries.perPage);
  // const statusesForFilter = useSelector(({ enquiries }) =>
  //   enquiries.statusesForFilter);

  // const _loadEnquiries = ({ page }) => {
  //   return dispatch(loadEnquiries({ page }))
  //     .unwrap()
  //     .catch(err => {
  //       if (err && err?.code === 401) {
  //         return dispatch(logOut());
  //       }
  //     });
  // };

  useEffect(() => {
    if (!isLoggedIn) {
      return navigate('/login', { replace: true });
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (
      (access && access.approveFlashDeals !== true) &&
      isAdmin.any !== true
    ) {
      return navigate('/', { replace: true });
    }
  });

  useEffect(() => {
    if (!isLoading && !showNoListings) {
      dispatch(loadFlashDealsList())
        .unwrap()
        .catch(err => {
          if (err && err?.code === 401) {
            return dispatch(logOut());
          }

          setShowNoListings(true);
        });
    }
  }, []);

  // // Event handlers

  const onClickSelectFlashDeal = flashDealId => {
    // console.log('SELECT', flashDealId);
    dispatch(selectFlashDeal({ id: flashDealId }));
  };

  // // / Event handlers

  return (
    <>
      <h1 className="mb-4">Moderate Flash Deals</h1>

      {showNoListings && <Alert variant="info">
        There are no Flash Deals to moderate.
      </Alert>}

      {!isLoading && flashDeals && <Table borderless striped hover>
        <tbody>
          {flashDeals.map(flashDeal =>
            <tr key={`flsdls_list_${flashDeal.id}`}>
              <td className="p-0">
                <Link
                  className="d-block p-3 text-decoration-none"
                  to={`/moderate-flash-deals/${flashDeal.id}`}
                  onClick={() => onClickSelectFlashDeal(flashDeal.id)}
                >
                  <Stack
                    className="align-items-center text-body"
                    direction="horizontal"
                    gap="3"
                  >
                    <Stack gap={2}>
                      <div className="fs-6">
                        <span className="fw-bold">
                          {flashDeal.name}
                        </span>: <span className="text-muted">
                          &quot;{flashDeal.summary}&quot;
                        </span>
                      </div>
                      <div className="fs-7 text-muted">
                        <span className="fw-bold">
                          {friendlyDate(flashDeal.from)}
                        </span> &gt; <span className="fw-bold">
                          {friendlyDate(flashDeal.to)}
                        </span>
                      </div>
                    </Stack>

                    <FlashDealStatusPill status={flashDeal.status} />

                    <Button className="ms-2" variant="primary">Edit</Button>
                  </Stack>
                </Link>
              </td>
            </tr>
          )}
        </tbody>
      </Table>}
    </>
  );
};
