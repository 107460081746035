import React, { useEffect } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";

// Bootstrap
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import { loadListing, submitListing } from '../store/actions';

export const MyListing = () => {
  const dispatch = useDispatch();

  const isLoadingSelected = useSelector(
    ({ myListings }) => myListings.isLoadingSelected
  );
  const listing = useSelector(
    ({ myListings }) => myListings.selectedListing
  );
  const selectedListingId = useSelector(
    ({ myListings }) => myListings.selectedListingId
  );
  const isSubmitting = useSelector(
    ({ myListings }) => myListings.isSubmitting
  );

  const defaultValues = listing ?
    {
      info: listing.info,
      phoneNumber: listing.phoneNumber,
      ticketUrl: listing.ticketUrl,
    } :
    {};

  const {
    clearErrors,
    control,
    handleSubmit,
    reset,
    setError,
    watch,
    formState: { errors, isSubmitSuccessful }
  } = useForm({
    defaultValues
  });

  useEffect(() => {
    if (selectedListingId && !listing) {
      dispatch(loadListing({ id: selectedListingId }));
    }
  }, [listing]);

  // Reset the form on listing load
  useEffect(() => {
    if (listing) {
      reset(defaultValues);
    }
  }, [listing, reset]);

  if (isLoadingSelected === true || !listing) {
    return null;
  }

  // With have onSubmit and onSubmitForm below to allow manual clearErrors()
  // as react-hook-form requires manual clearing for general form errors.
  const onSubmit = data => {
    return dispatch(submitListing({
      ...data,
      id: selectedListingId
    }))
      .unwrap()
      .catch(err => {
        if (err && err?.code === 401) {
          return dispatch(logOut());
        }
        else {
          setError(
            err.meta && err.meta.field ? err.meta.field : 'form',
            {
              type: 'server',
              message: err.error || err.toString()
            }
          );
        }
      });
  };
  const onSubmitForm = e => {
    clearErrors();
    return handleSubmit(onSubmit)(e);
  };

  return (
    <>
      <h1 className="mb-4">
        <Link className="text-muted" to="/my-listings">My Listings</Link>
        {' / '}
        {listing.name}
      </h1>

      <Form onSubmit={onSubmitForm}>

        {isSubmitSuccessful && <Alert variant="success">
          Changes saved!
        </Alert>}

        {errors.form && <Alert variant="danger">
          {errors.form?.message}
        </Alert>}

        <Row className="mb-3">
          {/* Listing details */}
          <Col className="mb-3" lg={8} sm={12}>
            <Container className="bg-white p-3">
              <Row>
                <Col className="mb-3" lg={6}>
                  <Form.Group>
                    <span className="fs-7 text-muted text-uppercase">
                      Info
                    </span>

                    <Controller
                      defaultValue=""
                      control={control}
                      name="info"
                      render={({
                        field: { onBlur, onChange, ref, value },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState
                      }) => (<Form.Control
                        as="textarea"
                        isInvalid={error}
                        onBlur={onBlur}
                        onChange={onChange}
                        placeholder="Simple text description of your location..."
                        ref={ref}
                        rows="7"
                        value={value}
                      />)}
                      rules={{
                        required: {
                          message: 'Please enter location info.',
                          value: true
                        }
                      }}
                    />

                    {errors.info && <Form.Control.Feedback type="invalid">
                      {errors.info?.message}
                    </Form.Control.Feedback>}

                    <Form.Text className="fs-7 text-muted">
                      A simple, text-only description of your location.
                    </Form.Text>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <span className="fs-7 text-muted text-uppercase">
                      Telephone number
                    </span>

                    <Controller
                      defaultValue=""
                      control={control}
                      name="phoneNumber"
                      render={({
                        field: { onBlur, onChange, ref, value },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState
                      }) => (<Form.Control
                        onBlur={onBlur}
                        onChange={onChange}
                        isInvalid={error}
                        placeholder="Phone number"
                        ref={ref}
                        type="tel"
                        value={value}
                      />)}
                    />

                    {errors.phoneNumber && <Form.Control.Feedback type="invalid">
                      {errors.phoneNumber?.message}
                    </Form.Control.Feedback>}

                    <Form.Text className="fs-7 text-muted">
                      A contact phone number. Include international dialing
                      codes if users should be able to call you from anywhere.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <span className="fs-7 text-muted text-uppercase">
                      Booking URL
                    </span>

                    <Controller
                      defaultValue=""
                      control={control}
                      name="ticketUrl"
                      render={({
                        field: { onBlur, onChange, ref, value },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState
                      }) => (<Form.Control
                        onBlur={onBlur}
                        onChange={onChange}
                        isInvalid={error}
                        placeholder="Url"
                        ref={ref}
                        type="url"
                        value={value}
                      />)}
                    />

                    {errors.ticketUrl && <Form.Control.Feedback type="invalid">
                      {errors.ticketUrl?.message}
                    </Form.Control.Feedback>}

                    <Form.Text className="fs-7 text-muted">
                      The URL of a website which users can visit for more info,
                      or to make bookings directly.
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
            </Container>
          </Col>

          {/* Listing sidebar */}
          <Col lg={4}>
            <Container className="bg-white">
              <Row className="p-3 align-items-center">
                <Button type="submit" variant="primary">Save Changes</Button>
              </Row>
            </Container>
          </Col>
        </Row>

      </Form>
    </>
  );
};
