'use strict';

import { createSlice, current } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import { logIn, logOut } from '../../auth/store/actions';
import {
  loadListing,
  loadListings,
  submitListing,
  updateSingleField
} from './actions';

import { listingsPerPage } from '../../../../common/config';

export const initialState = {
  appsForFilter: [],
  incUnpublishedFilter: false,
  isLoadingListings: false,
  isLoadingSelected: false,
  listings: [],
  numTotal: 0,
  perPage: 0,
  selectedListing: null,
  selectedListingId: null,

  // Stuff just for handling multiple request submission
  currentRequestId: null,
  isSubmitting: false,
  updateFieldRequestId: null,
  isUpdatingField: false
};

// Common log out callback
const commonLogOutCallback = (state, action) => {
  return { ...initialState };
};

// Create exploreSlice store slice
const exploreSlice = createSlice({
  name: 'explore',

  initialState,

  reducers: {
    // Select listing
    selectListing: (state, action) => {
      state.selectedListing = null;
      state.selectedListingId = action.payload.id;
    },

    // Update app filter
    setAppFilter: (state, action) => {
      const { id, appFilterState } = action.payload;
      const thisApp = state.appsForFilter.find(a => a.id === id);
      if (thisApp) {
        thisApp.active = !!appFilterState;
        state.appsForFilter = [...state.appsForFilter];
      }
    },

    // Update inc unpublished filter
    setIncUnpublishedFilter: (state, action) => {
      state.incUnpublishedFilter = action.payload.incUnpublishedFilterState;
    }
  },

  extraReducers: builder => {
    // Load selected listing PENDING
    builder.addCase(loadListing.pending, state => {
      state.isLoadingSelected = true;
      state.selectedListing = null;
    });
    // Load selected listing FULFILLED
    builder.addCase(loadListing.fulfilled, (state, action) => {
      const { data = null } = action.payload;

      return {
        ...state,
        selectedListing: data,
        selectedListingId: data.id,
        isLoadingSelected: false
      };
    });
    // Load selected listing REJECTED
    builder.addCase(loadListing.rejected, state => {
      return {
        ...state,
        selectedListing: null,
        isLoadingSelected: false
      };
    });

    // Load listings PENDING
    builder.addCase(loadListings.pending, state => {
      state.isLoadingListings = true;
      state.selectedListingId = null;
    });
    // Load listings FULFILLED
    builder.addCase(loadListings.fulfilled, (state, action) => {
      const { data = [], meta = {} } = action.payload;

      return {
        ...state,
        listings: data,
        isLoadingListings: false,
        numTotal: meta.numTotal || 0,
        perPage: meta.perPage || listingsPerPage
      };
    });
    // Load listings REJECTED
    builder.addCase(loadListings.rejected, state => {
      return {
        ...state,
        listings: [],
        isLoadingListings: false,
        numTotal: 0,
        perPage: listingsPerPage
      };
    });

    // Update listing single field PENDING
    builder.addCase(updateSingleField.pending, (state, action) => {
      if (!state.isUpdatingField) {
        state.isUpdatingField = true;
        state.updateFieldRequestId = action.meta.requestId;
      }
    });
    // Update listing single field FULFILLED
    builder.addCase(updateSingleField.fulfilled, (state, action) => {
      if (
        state.isUpdatingField &&
        state.updateFieldRequestId === action.meta.requestId
      ) {
        const { data = null, field, selectedListingId, value } = action.payload;

        state.isUpdatingField = false;
        state.updateFieldRequestId = undefined;

        // Update the collection
        const allUpdatedIds = data.map(d => d.id);
        const listings = [
          ...state.listings.filter(l => allUpdatedIds.indexOf(l.id) < 0),
          ...data
        ];
        // Re-sort the new collection by name
        listings.sort((a, b) => a.name.localeCompare(b.name));

        state.listings = listings;

        // Called from the Edit form, update selected listing
        if (selectedListingId) {
          state.selectedListing[field] = value;
        }
      }
    });
    // Update listing single field REJECTED
    builder.addCase(updateSingleField.rejected, (state, action) => {
      if (
        state.isUpdatingField &&
        state.updateFieldRequestId === action.meta.requestId
      ) {
        state.isUpdatingField = false;
        state.updateFieldRequestId = undefined;
      }
    });

    // Submit Edit form PENDING
    builder.addCase(submitListing.pending, (state, action) => {
      if (!state.isSubmitting) {
        state.isSubmitting = true;
        state.currentRequestId = action.meta.requestId;
      }
    });
    // Submit Edit form FULFILLED
    builder.addCase(submitListing.fulfilled, (state, action) => {
      if (
        state.isSubmitting &&
        state.currentRequestId === action.meta.requestId
      ) {
        const { data = null } = action.payload;

        state.isSubmitting = false;
        state.currentRequestId = undefined;

        // Update the collection entry
        const updatedId = data.listings[0].id;
        const listings = [
          ...state.listings.filter(l => updatedId.indexOf(l.id) < 0),
          ...data.listings
        ];
        // Resort the new collection by name
        listings.sort((a, b) => a.name.localeCompare(b.name));

        state.listings = listings;

        // Called from the Edit form, update selected listing
        state.selectedListing = data.selectedListing;
      }
    });
    // Submit Edit form REJECTED
    builder.addCase(submitListing.rejected, (state, action) => {
      if (
        state.isSubmitting &&
        state.currentRequestId === action.meta.requestId
      ) {
        state.isSubmitting = false;
        state.currentRequestId = undefined;
      }
    });

    // On log in FULFILLED
    builder.addCase(logIn.fulfilled, (state, action) => {
      state.appsForFilter = action.payload.data.allowedApps.map(app => {
        return {
          active: true,
          id: app.appId,
          label: app.name
        };
      });
    });

    // Log out fulfilled or rejected due to error - reset state
    builder.addCase(logOut.fulfilled, commonLogOutCallback);
    builder.addCase(logOut.rejected, commonLogOutCallback);
  }
});

export const whitelist = [
  'appsForFilter',
  'incUnpublishedFilter',
  'numTotal',
  'perPage',
  'selectedListingId'
];

export const {
  selectListing,
  setAppFilter,
  setIncUnpublishedFilter
} = exploreSlice.actions;
export const reducer = exploreSlice.reducer;
