import { createSlice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import { logIn, logOut } from './actions';

const initialState = {
  access: null,
  allowedApps: [],
  currentRequestId: null,
  exploreIds: [],
  isLoggedIn: false,
  isLoggingIn: false,
  isTWMEAdmin: false,
  isSuperAdmin: false,
  name: ''
};

// Common log out callback
const commonLogOutCallback = (state, action) => {
  return { ...initialState };
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: builder => {
    // Log out fulfilled or rejected due to error - reset state
    builder.addCase(logOut.fulfilled, commonLogOutCallback);
    builder.addCase(logOut.rejected, commonLogOutCallback);

    // Login PENDING
    builder.addCase(logIn.pending, (state, action) => {
      if (!state.isLoggingIn) {
        state.currentRequestId = action.meta.requestId;
        state.isLoggingIn = true;
      }
    });

    // Login FULLFILLED
    builder.addCase(logIn.fulfilled, (state, action) => {
      const { requestId } = action.meta;

      if (state.isLoggingIn && state.currentRequestId === requestId) {
        const { data } = action.payload;

        return {
          ...state,

          // Resets
          currentRequestId: undefined,
          isLoggingIn: false,

          // New values
          access: data.access,
          allowedApps: data.allowedApps,
          exploreIds: data.exploreIds,
          isLoggedIn: true,
          isTWMEAdmin: data.isTWMEAdmin && data.isTWMEAdmin === true ?
            true : false,
          isSuperAdmin: data.isSuperAdmin && data.isSuperAdmin === true ?
            true : false,
          name: data.name
        };
      }
    });

    // Login REJECTED
    builder.addCase(logIn.rejected, (state, action) => {
      const { requestId } = action.meta;

      if (state.isLoggingIn && state.currentRequestId === requestId) {
        state.isLoggingIn = false;
        state.currentRequestId = undefined;
      }
    });
  }
});

export const whitelist = [
  'access',
  'allowedApps',
  'exploreIds',
  'isLoggedIn',
  'isTWMEAdmin',
  'isSuperAdmin',
  'name'
];

export const reducer = authSlice.reducer;
