import { createSlice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import { logOut } from '../../auth/store/actions';

import {
  loadFlashDeal,
  loadFlashDealsList,
  submitApprove,
  submitReject
} from './actions';

const initialState = {
  currentRequestId: null,
  flashDeals: [],
  isLoading: false,
  isSubmitting: false,
  selectedFlashDeal: null,
  selectedFlashDealId: null,
};

// Common log out callback
const commonLogOutCallback = (state, action) => {
  return { ...initialState };
};

export const moderateSlice = createSlice({
  name: 'moderate',

  initialState,

  reducers: {
    // Select Flash Deal
    selectFlashDeal: (state, action) => {
      state.selectedFlashDeal = null;
      state.selectedFlashDealId = action.payload.id;
    }
  },

  extraReducers: builder => {
    // Load FD listings PENDING
    builder.addCase(loadFlashDealsList.pending, state => {
      state.isLoading = true;
      state.selectedListingId = null;
    });
    // Load FD listings FULFILLED
    builder.addCase(loadFlashDealsList.fulfilled, (state, action) => {
      const { data = [], meta = {} } = action.payload;

      return {
        ...state,
        flashDeals: data,
        isLoading: false
      };
    });
    // Load FD listings REJECTED
    builder.addCase(loadFlashDealsList.rejected, state => {
      return {
        ...state,
        flashDeals: [],
        isLoading: false
      };
    });

    // Load selected FD PENDING
    builder.addCase(loadFlashDeal.pending, state => {
      state.isLoading = true;
      state.selectedFlashDeal = null;
    });
    // Load selected FD FULFILLED
    builder.addCase(loadFlashDeal.fulfilled, (state, action) => {
      const { data = null } = action.payload;

      return {
        ...state,
        selectedFlashDeal: data,
        isLoading: false
      };
    });
    // Load selected FD REJECTED
    builder.addCase(loadFlashDeal.rejected, state => {
      return {
        ...state,
        selectedFlashDeal: null,
        isLoading: false
      };
    });

    // Submit FD approval
    builder.addCase(submitApprove.pending, (state, action) => {
      if (!state.isSubmitting) {
        state.currentRequestId = action.meta.requestId;
        state.isSubmitting = true;
      }
    });
    // Submit FD approval
    builder.addCase(submitApprove.fulfilled, (state, action) => {
      if (
        state.isSubmitting &&
        state.currentRequestId === action.meta.requestId
      ) {
        const { data = null } = action.payload;

        return {
          ...state,
          selectedFlashDeal: data,

          // Resets
          currentRequestId: undefined,
          isSubmitting: false
        };
      }
    });
    // Submit FD approval
    builder.addCase(submitApprove.rejected, (state, action) => {
      if (
        state.isSubmitting &&
        state.currentRequestId === action.meta.requestId
      ) {
        state.isSubmitting = false;
        state.currentRequestId = undefined;
      }
    });

    // Submit FD rejection
    builder.addCase(submitReject.pending, (state, action) => {
      if (!state.isSubmitting) {
        state.currentRequestId = action.meta.requestId;
        state.isSubmitting = true;
      }
    });
    // Submit FD rejection
    builder.addCase(submitReject.fulfilled, (state, action) => {
      if (
        state.isSubmitting &&
        state.currentRequestId === action.meta.requestId
      ) {
        return {
          ...state,
          selectedFlashDeal: action.payload.data,

          // Resets
          currentRequestId: undefined,
          isSubmitting: false
        };
      }
    });
    // Submit FD rejection
    builder.addCase(submitReject.rejected, (state, action) => {
      if (
        state.isSubmitting &&
        state.currentRequestId === action.meta.requestId
      ) {
        state.isSubmitting = false;
        state.currentRequestId = undefined;
      }
    });

    // Log out fulfilled or rejected due to error - reset state
    builder.addCase(logOut.fulfilled, commonLogOutCallback);
    builder.addCase(logOut.rejected, commonLogOutCallback);
  }
});

export const whitelist = [
  'flashDeals',
  'selectedFlashDeal',
  'selectedFlashDealId'
];

export const { selectFlashDeal } = moderateSlice.actions;
export const reducer = moderateSlice.reducer;
