import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import Datetime from 'react-datetime';
import Countdown from 'react-countdown';
import moment from 'moment-timezone';

// Bootstrap
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import Table from 'react-bootstrap/Table';

// Components
import { FlashDealStatusPill } from '../components/FlashDealStatusPill';

// Actions
import {
  loadFlashDeal,
  submitCancelFlashDeal,
  submitUpdatedFlashDeal
} from '../store/actions';
import { logOut } from '../../auth/store/actions';

// Util
import { devLog } from '../../../lib/util/devLog';
import { friendlyDate } from '../../../lib/util/dates';

import { defaultAccess } from '../../../../common/config';

// Show a list of errors  
const showErrorsList = errors => {
  return (
    <ul className="mb-0">
      {errors.map((error, index) => <li key={`erlst_${index}`}>{error}</li>)}
    </ul>
  );
};

export const EditFlashDealForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const exploreSearchRef = useRef();

  const selectedFlashDealId = useSelector(
    ({ myListings }) => myListings.selectedFlashDealId
  );
  let flashDealListingName = useSelector(
    ({ myListings }) => myListings.flashDealListingName
  );
  const selectedFlashDeal = useSelector(
    ({ myListings }) => myListings.selectedFlashDeal
  );

  const isLoadingSelected = useSelector(
    ({ myListings }) => myListings.isLoadingSelected
  );
  const isSubmitting = useSelector(
    ({ myListings }) => myListings.isSubmitting
  );

  // Earliest date/time for validation
  const earliestDateTime = moment()
    .add(1, 'minute')
    .add(24, 'hours');
  // Latest date/time for placeholder only (as real latest is From + 7 days)
  const latestDateTime = moment()
    .add(24, 'hours')
    .add(7, 'days');

  // Disabled flag for field that can't be changed once the FD is approved
  let endDateOnly = false;
  if (selectedFlashDeal && (selectedFlashDeal.status === 'approved' ||
    selectedFlashDeal.status === 'live')) {
    endDateOnly = true;
  }

  // Get form defaults
  const defaultValues = selectedFlashDeal ? {
    summary: selectedFlashDeal.summary,
    details: selectedFlashDeal.details,
    from: moment(selectedFlashDeal.from),
    to: moment(selectedFlashDeal.to),
    code: selectedFlashDeal.code,
    status: selectedFlashDeal.status,
    history: selectedFlashDeal.history
  } : {};

  const {
    clearErrors,
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors, isSubmitSuccessful }
  } = useForm({
    defaultValues
  });


  // Load the FD if needed
  useEffect(() => {
    if (selectedFlashDealId && !selectedFlashDeal && !isLoadingSelected) {
      dispatch(loadFlashDeal({ id: selectedFlashDealId }));
    }
  }, [selectedFlashDeal]);

  // Reset the form on FD load
  useEffect(() => {
    if (selectedFlashDeal) {
      reset(defaultValues);
    }
  }, [selectedFlashDeal, reset]);

  // Sort history by desc date
  // selectedFlashDeal.history.sort((a, b) => {
  //   // Turn your strings into dates, and then subtract them
  //   // to get a value that is either negative, positive, or zero.
  //   return new Date(b.dateTime) - new Date(a.dateTime);
  // });

  // Event handlers

  // With have onSubmit and onSubmitForm below to allow manual clearErrors()
  // as react-hook-form requires manual clearing for general form errors.
  const onSubmit = data => {
    if (isSubmitting) {
      return;
    }

    // Last minute checks or things that can't validate automatically...
    const errors = [];
    // Extract data from form data object
    const submitData = (({ from, to, details, summary }) =>
      ({ from, to, details, summary }))(data);

    // Taking the whole form?
    if (!endDateOnly) {
      // // Extract data from formdata object, and add processed fields
      // submitData = (({ from, to, details, summary }) =>
      //   ({ from, to, details, summary }))(data);


      // Check to date isn't before from date
      if (submitData.to.isBefore(submitData.from)) {
        errors.push('The End Date/Time cannot be before the Start Date/Time.');
      }

      // Check from date is at least the earliest allowed
      if (submitData.from.isBefore(earliestDateTime)) {
        errors.push('The Start Date/Time is too early. Please allow at least ' +
          '24 hours for approval.');
      }

      // Check to date is no later than 7 days past the from date
      if (submitData.to.isAfter(moment(submitData.from).add(7, 'days'))) {
        errors.push('The End Date/Time is too late. The maximum length of ' +
          'a Flash Deal is 7 days.');
      }
    }
    else {
      // // Extract data from formdata object, and add processed fields
      // submitData = (({ from, to }) => ({ from, to }))(data);

      // Check to date isn't before from date
      if (submitData.to.isBefore(submitData.from)) {
        errors.push('The End Date/Time cannot be before the Start Date/Time.');
      }

      // Check to date is no later than 7 days past the from date
      if (submitData.to.isAfter(moment(submitData.from).add(7, 'days'))) {
        errors.push('The End Date/Time is too late. The maximum length of ' +
          'a Flash Deal is 7 days.');
      }
    }

    if (errors.length > 0) {
      setError('form', {
        message: showErrorsList(errors)
      });
      return;
    }

    submitData.from = submitData.from.toISOString();
    submitData.to = submitData.to.toISOString();

    return dispatch(submitUpdatedFlashDeal(submitData))
      .unwrap()
      .then(({ data = {} }) => {
        setTimeout(() => navigate('/my-listings'), 5000);
      })
      .catch(err => {
        if (err && err?.code === 401) {
          return dispatch(logOut());
        }
        else {
          // console.log('ERROR?', err);
          setError(
            err.meta && err.meta.field ? err.meta.field : 'form',
            {
              type: 'server',
              message: err.error || err.toString()
            }
          );
        }
      });
  };
  const onSubmitForm = e => {
    clearErrors();
    return handleSubmit(onSubmit, (err) => {
      // Catching submission errors
    })(e);
  };

  const onSubmitCancel = e => {
    if (window.confirm(
      'Please confirm CANCELLATION.\n\nThis can not be undone.'
    )) {
      clearErrors();
      return handleSubmit(data => {

        return dispatch(submitUpdatedFlashDeal(submitData))
          .unwrap()
          .then(({ data = {} }) => {
            setTimeout(() => navigate('/my-listings'), 5000);
          })
          .catch(err => {
            if (err && err?.code === 401) {
              return dispatch(logOut());
            }
            else {
              // console.log('ERROR?', err);
              setError(
                err.meta && err.meta.field ? err.meta.field : 'form',
                {
                  type: 'server',
                  message: err.error || err.toString()
                }
              );
            }
          });
      }, (err) => {
        // Catching submission errors
      })(e);
    }
    else {
      e.preventDefault();
    }
  };

  // / Event handlers

  // Render functions

  const renderHistory = history => {
    // Sort history by desc date
    const sorted = [...history].sort((a, b) => {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.dateTime) - new Date(a.dateTime);
    });

    return (
      <div className="bg-white p-3">
        <h6 className="text-muted">History</h6>
        <Table striped borderless hover>
          <tbody>
            {sorted && sorted.map(
              h => (
                <tr key={`fdhst_${h.dateTime}`}><td>
                  <Row className="px-3 py-1 align-items-center">
                    <Col className="p-0 text-muted w-25">Status</Col>
                    <Col className="p-0" xs="8">
                      {h.status.toUpperCase()}
                    </Col>
                  </Row>
                  <Row className="px-3 py-1 align-items-center">
                    <Col className="p-0 text-muted w-25">Date/Time</Col>
                    <Col className="p-0" xs="8">
                      {friendlyDate(h.dateTime)}
                    </Col>
                  </Row>
                  {h.info && <Row className="px-3 py-1 align-items-center">
                    <Col className="p-0" xs="8">
                      {h.info}
                    </Col>
                  </Row>}
                </td></tr>
              )
            )}
          </tbody>
        </Table>
      </div>
    );
  };

  const renderSubmitButton = (status) => {
    let showButton = false;
    let text = null;

    switch (status) {
      case 'rejected':
        showButton = true;
        text = `This Flash Deal was rejected. The reason is given below. You
          can edit it and resubmit it for approval.`;
        break;
      case 'submitted':
        showButton = true;
        text = 'This Flash Deal has yet to be approved, and can be edited.';
        break;
      case 'approved':
      case 'live':
        showButton = true;
        text = `This Flash Deal's status is ${status.toUpperCase()}. Once
          approved, only the End Date and Time can be changed.`;
        break;
      default:
        text = `This Flash Deal's status is ${status.toUpperCase()} and cannot
          be edited.`;
    }
    return (
      <div className="bg-white p-3">
        {showButton && <Button
          className="w-100 mb-2"
          onClick={onSubmitForm}
          type="submit"
          variant="primary"
        >
          Submit Changes
        </Button>}
        <Form.Text className="fs-7 text-muted">
          {text}
        </Form.Text>
      </div>
    );
  };

  const renderCancelButton = (status) => {
    let showButton = false;
    let text = null;

    switch (status) {
      case 'submitted':
        showButton = true;
        text = 'This Flash Deal has yet to be approved, and can be cancelled.';
        break;
      case 'approved':
      case 'live':
        showButton = true;
        text = `This Flash Deal's status is ${status.toUpperCase()}. You can ` +
          'cancel it at any time. There may be a delay before the change is ' +
          'in the app for all users.';
        break;
    }

    if (!showButton) {
      return null;
    }

    return (
      <div className="bg-white p-3">
        {showButton && <Button
          className="w-100 mb-2"
          onClick={onSubmitCancel}
          type="submit"
          variant="danger"
        >
          Cancel Flash Deal
        </Button>}
        <Form.Text className="fs-7 text-muted">
          {text}
        </Form.Text>
      </div>
    );
  };

  // / Render functions

  return (
    <>
      {selectedFlashDeal && <>
        <h1 className="mb-4">
          <Link className="text-muted" to="/my-listings">My Listings</Link>
          {' / Edit Flash Deal'}
        </h1>

        <Form>

          {isSubmitSuccessful && <Alert variant="success">
            Flash Deal submitted for approval. <span className="text-muted">
              Back to My Listings in <Countdown
                date={Date.now() + 5000}
                renderer={({ seconds }) => <span>{seconds}s</span>}
              />
            </span>
          </Alert>}

          {errors.form && <Alert variant="danger">
            {errors.form?.message}
          </Alert>}

          <Row className="mb-3">
            {/* Login details */}
            <Col className="mb-3" lg={8} sm={12}>
              <Container className="bg-white p-3">
                {/* TOP ROW - LISTING NAME */}
                <Row className="mb-3">
                  <Col className="mb-0">
                    <Form.Group>
                      <span className="fs-7 text-muted text-uppercase">
                        Editing Flash Deal for this listing:
                      </span>

                      <Form.Control
                        disabled={true}
                        value={flashDealListingName}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>

                  {/* LEFT COLUMN */}
                  <Col className="mb-3" lg={6}>

                    {/* Start Date and Time */}
                    <Form.Group className="mb-3">
                      <span className="fs-7 text-muted text-uppercase">
                        Start Date and Time
                      </span>

                      {!endDateOnly && <Controller
                        defaultValue=""
                        control={control}
                        name="from"
                        render={({
                          field: { onBlur, onChange, ref, value },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState
                        }) => (<Datetime
                          dateFormat="MMM Do YYYY"
                          inputProps={{
                            placeholder: 'eg. ' + earliestDateTime
                              .format('MMM Do YYYY HH:mm')
                          }}
                          isInvalid={error}
                          onChange={onChange}
                          ref={ref}
                          timeFormat="HH:mm"
                          timeConstraints={{
                            minutes: { min: 0, max: 59, step: 15 }
                          }}
                          value={value}
                        />)}
                        rules={{ required: true }}
                      />}
                      {endDateOnly && <Form.Control
                        disabled={true}
                        value={moment(selectedFlashDeal.from)
                          .format('MMM Do YYYY HH:mm')}
                      />}

                      {errors.from && <Form.Control.Feedback type="invalid">
                        {errors.from?.message}
                      </Form.Control.Feedback>}

                      <Form.Text className="fs-7 text-muted">
                        The date and time the Flash Deal should begin. It must be
                        at least 24 hours from the time of submission.
                      </Form.Text>
                    </Form.Group>

                    {/* End Date and Time */}
                    <Form.Group className="mb-3">
                      <span className="fs-7 text-muted text-uppercase">
                        End Date and Time
                      </span>

                      <Controller
                        defaultValue=""
                        control={control}
                        name="to"
                        render={({
                          field: { onBlur, onChange, ref, value },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState
                        }) => (<Datetime
                          dateFormat="MMM Do YYYY"
                          inputProps={{
                            placeholder: 'eg. ' + latestDateTime
                              .format('MMM Do YYYY HH:mm')
                          }}
                          isInvalid={error}
                          onChange={onChange}
                          ref={ref}
                          timeFormat="HH:mm"
                          timeConstraints={{
                            minutes: { min: 0, max: 59, step: 15 }
                          }}
                          value={value}
                        />)}
                        rules={{ required: true }}
                      />

                      {errors.to && <Form.Control.Feedback type="invalid">
                        {errors.to?.message}
                      </Form.Control.Feedback>}

                      <Form.Text className="fs-7 text-muted">
                        The date and time of the end of the Flash Deal. It must be
                        a maximum of 7 days (full 24 hour periods) from the Start
                        Date.
                      </Form.Text>
                    </Form.Group>

                    {/* Deal code */}
                    <Form.Group>
                      <span className="fs-7 text-muted text-uppercase">
                        Flash Deal Code
                      </span>

                      <Form.Control
                        disabled={true}
                        // isInvalid={error}
                        // onBlur={onBlur}
                        // onChange={onChange}
                        value={selectedFlashDeal.code}
                      />

                      <Form.Text className="fs-7 text-muted">
                        The Flash Deal's unique code. Users must unlock the app to
                        see this code, and must show it at the location to use the
                        offer. This random 8-character code will be generated
                        automatically when you submit this Flash Deal.
                      </Form.Text>
                    </Form.Group>

                  </Col>

                  {/* RIGHT COLUMN */}
                  <Col lg={6}>

                    {/* Deal summary */}
                    <Form.Group className="mb-3">
                      <span className="fs-7 text-muted text-uppercase">
                        Short Summary
                      </span>

                      <Controller
                        defaultValue=""
                        control={control}
                        name="summary"
                        render={({
                          field: { onBlur, onChange, ref, value },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState
                        }) => (<Form.Control
                          disabled={endDateOnly}
                          isInvalid={error}
                          onBlur={onBlur}
                          onChange={onChange}
                          maxLength="50"
                          placeholder="eg. 10% of all double rooms"
                          ref={ref}
                          value={value}
                        />)}
                        rules={{
                          required: {
                            message: 'Please enter a short summary of the deal.',
                            value: true
                          }
                        }}
                      />

                      <span>{ }</span>

                      {errors.summary && <Form.Control.Feedback type="invalid">
                        {errors.summary?.message}
                      </Form.Control.Feedback>}

                      <Form.Text className="fs-7 text-muted">
                        A short summary of the deal. Maximum of 50 characters.
                      </Form.Text>
                    </Form.Group>

                    {/* Deal details */}
                    <Form.Group>
                      <span className="fs-7 text-muted text-uppercase">
                        Details
                      </span>

                      <Controller
                        defaultValue=""
                        control={control}
                        name="details"
                        render={({
                          field: { onBlur, onChange, ref, value },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState
                        }) => (<Form.Control
                          disabled={endDateOnly}
                          as="textarea"
                          isInvalid={error}
                          onBlur={onBlur}
                          onChange={onChange}
                          ref={ref}
                          rows="10"
                          value={value}
                        />)}
                        rules={{
                          required: {
                            message: 'Please enter full details of the deal.',
                            value: true
                          }
                        }}
                      />

                      {errors.details && <Form.Control.Feedback type="invalid">
                        {errors.details?.message}
                      </Form.Control.Feedback>}

                      <Form.Text className="fs-7 text-muted">
                        Full details of the deal, including any conditions,
                        exclusions or requirements.
                      </Form.Text>
                    </Form.Group>

                  </Col>
                </Row>
              </Container>
            </Col>

            {/* Set Up FD sidebar */}
            <Col lg={4}>
              <Stack gap={3}>
                {renderSubmitButton(selectedFlashDeal.status)}

                <div className="bg-white p-3">
                  <Row className="bg-white px-3 align-items-center">
                    <Col className="px-0 text-muted w-25">Status</Col>
                    <Col className="px-0 align-items-center" xs="8">
                      <FlashDealStatusPill status={selectedFlashDeal.status} />
                    </Col>
                  </Row>
                </div>

                {renderCancelButton(selectedFlashDeal.status)}

                {selectedFlashDeal?.history.length > 0 &&
                  renderHistory(selectedFlashDeal.history)}
              </Stack>
            </Col>
          </Row>

        </Form>
      </>}
    </>
  );
};
