import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TimeAgo from 'timeago-react';

// Bootstrap
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import Table from 'react-bootstrap/Table';

import { loadResponses } from '../store/actions';

export const ResponsesList = props => {
  const { numResponses, selectedEnquiryId } = props;

  const dispatch = useDispatch();

  const isLoadingResponses =
    useSelector(({ responses }) => responses.isLoadingResponses);
  const numTotal = useSelector(({ responses }) => responses.numTotal);
  const responses = useSelector(({ responses }) => responses.responses);

  useEffect(() => {
    // Load responses only if enquiry.numReponses !== response reducer numTotal
    if (!isLoadingResponses && numResponses !== numTotal) {
      dispatch(loadResponses({ id: selectedEnquiryId }));
    }
  }, [numResponses, numTotal]);

  if (responses.length === 0) {
    return null;
  }

  return (
    <>
      <div className="bg-secondary fs-5 mb-1 p-3 text-light">Responses</div>

      <div>
        {!isLoadingResponses && responses.map(response =>
          <div className="bg-white mb-1" key={`resp_list_${response.id}`}>
            <Stack>
              <div className="bg-light border-bottom d-flex flex-row fs-7 justify-content-between py-2 px-3">
                <span>
                  <span className="fw-bold">{response.userName}</span>{' '}
                  {response.reps && <span className="text-muted">
                    [{response.reps}]
                  </span>}
                </span>

                <TimeAgo datetime={response.createdAt} />
              </div>

              <div className="p-3">{response.body}</div>
            </Stack>
          </div>)}
      </div>
    </>
  );
};
