import React from 'react';
import { Outlet } from "react-router-dom";

// Bootstrap
import Container from 'react-bootstrap/Container';

import { SubNav } from '../../../lib/components/SubNav';

export const Layout = () => {
  return (
    <Container className="mt-2">
      <SubNav
        className="mb-4"
        defaultActiveKey="/manage-explore"
        links={[
          { to: '/manage-explore', text: 'Listings' },
          // { to: '/manage-explore/newest-offers', text: 'Newest Offers' },
          { to: '/manage-explore/categories', text: 'Top-Level Categories' },
          { to: '/manage-explore/filters', text: 'Filters' },
          { to: '/manage-explore/price-range-filter', text: 'Price Range Filter' },
        ]}
        variant="pills"
      />
      <Outlet />
    </Container>
  );
};
