'use strict';

import axios from 'axios';
import qs from 'qs';

import { devLog } from './devLog';
// import { KeyStore } from './KeyStore';

const catchError = (error, reqType, callback) => {
  // devLog(`api.${reqType} ERROR - request config:`, error.config);

  // Error response from server
  if (error.response) {
    // if (__DEV__) {
    //   console.log(`api.${reqType} ERROR with response:`);
    //   console.log('RES DATA', error.response.data);
    //   console.log('RES STATUS', error.response.status);
    //   console.log('RES HEADER', error.response.headers);
    // }

    // If there's a callback...
    if (callback) {
      callback(error.response);
    }

    throw error.response.data;
  }
  // Request not completed
  else if (error.request) {
    // devLog(`api.${reqType} ERROR request did not complete`);
    throw 'REQUEST_NOT_COMPLETED';
  }
  // Request errored while being setup
  else {
    // devLog(`api.${reqType} ERROR request setup failed`, error.message);
    throw error.message;
  }
}

// Parse out request params from options and get appropriate access token
const getReqParams = async opts => {
  const authType = opts.authType || 'public';
  let params = opts.params || null;

  switch (authType) {
    case 'auth':
      const token = await KeyStore.get('TOKEN');
      if (!token) {
        throw 'MISSING_TOKEN';
      }
      params = params ? Object.assign({ token }, params) : { token };
      break;

    case 'anon':
      const atoken = await KeyStore.get('ATOKEN');
      if (!atoken) {
        throw 'MISSING_ANON_TOKEN';
      }
      params = params ? Object.assign({ atoken }, params) : { atoken };
      break;
  }

  return params;
};

// Make a HEAD request
export async function apiHead(url, opts = {}) {
  const params = await getReqParams(opts);

  return axios.head(url, { params })
    .then((res) => {
      return;
    })
    .catch(error => { catchError(error, 'HEAD') });
};

// Make a GET request
export async function apiGet(url, opts = {}) {
  const headers = opts.headers || undefined;
  const params = opts.params || undefined;
  const callback = opts.callback || null;

  try {
    const response = await axios.get(url, { headers, params });

    return response || {};
  }
  catch (error) {
    catchError(error, 'GET', callback)
  }
};

// Make a POST request
export async function apiPost(url, opts = {}) {
  const headers = Object.assign(
    { 'content-type': 'application/x-www-form-urlencoded' },
    opts.headers || {}
  );

  const data = opts.data ? qs.stringify(opts.data) : null;

  const callback = opts.callback || null;
  try {
    let response;
    if (data) {
      response = await axios({
        data,
        headers,
        method: 'post',
        url
      });
    }
    else {
      response = await axios({
        headers,
        method: 'post',
        url
      });
    }

    return response || {};
  }
  catch (error) {
    catchError(error, 'POST', callback)
  }
};
