export const defaultAccess = {
  enquiries: {
    value: false,
    label: 'Handle Enquiries'
  },
  editListings: {
    value: false,
    label: 'Edit My Listings'
  },
  flashDeals: {
    value: false,
    label: 'Create/Edit Flash Deals'
  }
};

export const enquiryStatuses = [
  {
    id: 'open',
    label: 'Open'
  },
  {
    id: 'pending',
    label: 'Pending'
  },
  {
    id: 'closed',
    label: 'Closed'
  }
];

export const enquiriesPerPage = 10;
export const listingsPerPage = 10;
export const loginsPerPage = 10;
