'use strict';

import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiGet, apiPost } from '../../../lib/util/api';

export const logIn = createAsyncThunk(
  'auth/logIn',
  async (data, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, isLoggingIn } = getState().auth;
    if (!isLoggingIn || requestId !== currentRequestId) {
      return;
    }

    try {
      const response = await apiPost('/cp-api/auth/log-in', { data });
      return response.data;
    }
    catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const logOut = createAsyncThunk(
  'auth/logOut',
  async () => {
    try {
      const response = await apiPost('/cp-api/auth/log-out');
      return response.data;
    }
    catch (err) {
      throw rejectWithValue(err);
    }
  }
);
