import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";

// Bootstrap
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import Table from 'react-bootstrap/Table';

import { ReplyForm } from '../responses/components/ReplyForm';
import { ResponsesList } from '../responses/components/ResponsesList';

import { friendlyDate } from '../../../lib/util/dates';

import { loadEnquiry, updateEnquiryStatus } from '../store/actions';

export const Enquiry = () => {
  const dispatch = useDispatch();
  const isLoadingSelected = useSelector(
    ({ enquiries }) => enquiries.isLoadingSelected
  );
  const enquiry = useSelector(({ enquiries }) => enquiries.selectedEnquiry);
  const selectedEnquiryId = useSelector(
    ({ enquiries }) => enquiries.selectedEnquiryId
  );
  const isSubmitting = useSelector(({ responses }) => responses.isSubmitting);

  const lastUpdated = enquiry ? enquiry.updatedAt : null;

  useEffect(() => {
    if (selectedEnquiryId) {
      dispatch(loadEnquiry({ id: selectedEnquiryId }));
    }
  }, [selectedEnquiryId, lastUpdated]);

  if (isLoadingSelected === true || !enquiry) {
    return null;
  }

  let statusVariant;
  switch (enquiry.status) {
    case 'open':
      statusVariant = 'success';
      break;
    case 'pending':
      statusVariant = 'danger';
      break;
    case 'closed':
      statusVariant = 'light';
      break;
  }

  // On select new status to update
  const onSelectStatus = status => {
    return dispatch(updateEnquiryStatus({
      id: selectedEnquiryId,
      status
    }))
      .unwrap()
      .catch(err => {
        if (err && err?.code === 401) {
          return dispatch(logOut());
        }
      });
  };

  // Render the enquiry meta data
  const renderMetaData = () => {
    switch (enquiry.contactMsgType) {
      case 'booking':
        return (
          <>
            <Row className="mb-3">
              <Col>
                <span className="fs-7 text-muted text-uppercase">Arrival</span>
                <div>{friendlyDate(enquiry.meta.arrDate)}</div>
              </Col>
              <Col>
                <span className="fs-7 text-muted text-uppercase">Departure</span>
                <div>{friendlyDate(enquiry.meta.depDate)}</div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <span className="fs-7 text-muted text-uppercase">Adults</span>
                <div>{enquiry.meta.numAdults}</div>
              </Col>
              <Col>
                <span className="fs-7 text-muted text-uppercase">Children</span>
                <div>{enquiry.meta.numChildren}</div>
              </Col>
            </Row>
          </>
        );

      case 'date':
        return (
          <Row className="mb-3"><Col>
            <span className="fs-7 text-muted text-uppercase">Date</span>
            <div>{friendlyDate(enquiry.meta.enqDate)}</div>
          </Col></Row>
        );
    }
  };

  return (
    <>
      <h1 className="mb-4">
        <Link className="text-muted" to="/enquiries">Enquiries</Link>
        {' / '}
        {enquiry.contactMsgTypeLabel}
      </h1>

      <Row className="mb-3">
        {/* Enquiry details */}
        <Col lg={8}>
          <Container className="bg-white pt-3 p-3">
            {/* Sender name */}
            <Row className="mb-3">
              <Col>
                <span className="fs-7 text-muted text-uppercase">From</span>
                <div>{enquiry.userName}</div>
              </Col>
              <Col>
                <span className="fs-7 text-muted text-uppercase">Location</span>
                <div>{enquiry.targetName}</div>
              </Col>
            </Row>

            {/* Enquiry meta data */}
            {renderMetaData()}

            {/* Enquiry body */}
            <Row><Col>
              <span className="fs-7 text-muted text-uppercase">Body</span>
              <div>{enquiry.body}</div>
            </Col></Row>
          </Container>
        </Col>

        {/* Enquiry sidebar */}
        <Col lg={4}>
          <Container className="px-3">
            <Row className="bg-white p-3 pb-1 align-items-center">
              <Col className="px-0 text-muted w-25">Status</Col>
              <Col className="px-0" xs="8">
                <DropdownButton
                  align="end"
                  className="rounded-pill"
                  onSelect={onSelectStatus}
                  size="sm"
                  title={enquiry.statusLabel}
                  variant={statusVariant}
                >
                  <Dropdown.ItemText className="text-muted">
                    Set status to:
                  </Dropdown.ItemText>
                  <Dropdown.Item eventKey="open">Open</Dropdown.Item>
                  <Dropdown.Item eventKey="pending">Pending</Dropdown.Item>
                  <Dropdown.Item eventKey="closed">Closed</Dropdown.Item>
                </DropdownButton>
              </Col>
            </Row>

            <Row className="bg-white p-3 pt-2 align-items-center">
              <Col className="px-0 text-muted w-25">Submitted</Col>
              <Col className="px-0" xs="8">
                {friendlyDate(enquiry.createdAt)}
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>

      {/* Enquiry responses */}
      <Row className="mb-3"><Col lg={8}>
        <ResponsesList
          numResponses={enquiry.numResponses}
          selectedEnquiryId={selectedEnquiryId}
        />
      </Col></Row>

      {/* Reply form */}
      <Row><Col lg={8}>
        <ReplyForm
          isSubmitting={isSubmitting}
          selectedEnquiryId={selectedEnquiryId}
        />
      </Col></Row>
    </>
  );
};
