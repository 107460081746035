import React from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';

const SubNavLink = ({ link }) => {
  let resolved = useResolvedPath(link.to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Nav.Item>
      <Nav.Link active={match} as={Link} to={link.to}>{link.text}</Nav.Link>
    </Nav.Item>
  );
};

export const SubNav = ({ links, ...rest }) => {
  const renderLink = link => {
    const key = `ni-${link.to.replace(/\W/g, '')}-${link.text.toLowerCase()}`;

    return <SubNavLink key={key} link={link} />;
  };

  return (
    <Container>
      <Nav {...rest}>
        {links.map(renderLink)}
      </Nav>
    </Container>
  );
};
