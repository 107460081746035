import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from "react-router-dom";

// Bootstrap
import Container from 'react-bootstrap/Container';

import { SubNav } from '../../../lib/components/SubNav';

export const Layout = () => {
  const access = useSelector(({ auth }) => auth.access);
 
  const links = [{ to: '/my-listings', text: 'My Listings' }];

  if (access?.flashDeals) {
    links.push({ to: '/my-listings/flash-deals', text: 'Flash Deals' });
  }

  return (
    <Container className="mt-2">
      <SubNav
        className="mb-4"
        defaultActiveKey="/my-listings"
        links={links}
        variant="pills"
      />
      <Outlet />
    </Container>
  );
};
