import React, { useMemo } from 'react';

import RBPagination from 'react-bootstrap/Pagination';
import { LinkContainer } from 'react-router-bootstrap';

const calcPagination = (page, maxPage, maxLinksPerSide) => {
  // Small pagination is 2 x whatever maxLinksPerSide + current page
  const smallThreshold = maxLinksPerSide * 2 + 1;
  const isSmallPagination = maxPage <= smallThreshold;

  const pConf = {
    smallThreshold,
    first: false,
    prev: false,
    numLeftPages: maxLinksPerSide,
    leftEllipsis: false,
    rightEllipsis: false,
    numRightPages: maxLinksPerSide,
    next: false,
    last: false
  };


  // If we have more than one page, activate next/prev links
  if (maxPage > 1) {
    if (page > 0) {
      pConf.first = true;
      pConf.prev = true;
    }

    if (page < maxPage - 1) {
      pConf.last = true;
      pConf.next = true;
    }
  }

  // Near the pagination start
  if (page < maxLinksPerSide) {
    pConf.numLeftPages = maxLinksPerSide - (maxLinksPerSide - page);

    if (isSmallPagination) {
      const right = smallThreshold - 1 - pConf.numLeftPages;

      pConf.numRightPages = page + 1 + right > maxPage ?
        maxPage - pConf.numLeftPages - 1 :
        right;
    }
    else {
      pConf.numRightPages = maxLinksPerSide + (maxLinksPerSide - 1 - page);
    }
  }
  // Near the pagination end
  else if (page > (maxPage - 1 - maxLinksPerSide)) {
    pConf.numRightPages = maxLinksPerSide - (maxLinksPerSide - (maxPage - 1 - page));

    if (isSmallPagination) {
      const left = smallThreshold - 1 - pConf.numRightPages;

      pConf.numLeftPages = page - left < 0 ?
        maxPage - pConf.numRightPages - 1 :
        left;
    }
    else {
      pConf.numLeftPages = maxLinksPerSide + (maxLinksPerSide - (maxPage - page));
    }
  }

  // In the middle of pagination Ellipses
  if (maxPage > maxLinksPerSide * 2 + 1) {
    if (page > 2) {
      pConf.leftEllipsis = true;
    }

    if (page < maxPage - 2 - 1) {
      pConf.rightEllipsis = true;
    }
  }

  return pConf;
};

export const Pagination = props => {
  let {
    baseUrl,
    maxLinksPerSide = 2,
    numTotal,
    page,
    perPage,
    showSinglePage = true
  } = props;

  maxLinksPerSide = parseInt(maxLinksPerSide);
  numTotal = parseInt(numTotal);
  page = parseInt(page);
  perPage = parseInt(perPage);

  if (perPage >= numTotal && !showSinglePage) {
    return null;
  }

  const maxPage = Math.ceil(numTotal / perPage);

  // In case of funny business, ensure page is limited by maxPage
  if (page >= maxPage) {
    page = maxPage - 1;
  }

  // Without the values to calc the pagination give up
  if (Number.isNaN(page) || Number.isNaN(maxPage)) {
    return null;
  }

  const renderLinks = (pConf, maxPage) => {
    const links = [];

    if (maxPage > pConf.smallThreshold) {
      links.push(renderLink(0, {
        class: 'pagination-previous',
        disabled: !pConf.first,
        key: 'f',
        label: '\u00AB'
      }));
    }

    links.push(renderLink(page > 0 ? page - 1 : 0, {
      class: 'pagination-previous',
      disabled: !pConf.prev,
      key: 'p',
      label: '\u2039'
    }));

    if (pConf.leftEllipsis) {
      links.push(renderLink(0));
      links.push(<RBPagination.Ellipsis key="pl_le" disabled />);
    }

    if (pConf.numLeftPages > 0) {
      for (let i = pConf.numLeftPages; i > 0; i--) {
        let j = page - i;
        links.push(renderLink(j));
      }
    }

    links.push(renderLink(page));

    if (pConf.numRightPages > 0) {
      for (let i = 0; i < pConf.numRightPages; i++) {
        let j = page + i + 1;
        links.push(renderLink(j));
      }
    }

    if (pConf.rightEllipsis) {
      links.push(<RBPagination.Ellipsis key="pl_re" disabled />);
      links.push(renderLink(maxPage - 1));
    }

    links.push(renderLink(page < maxPage - 1 ? page + 1 : maxPage - 1, {
      class: 'pagination-next',
      disabled: !pConf.next,
      key: 'n',
      label: '\u203A'
    }));

    if (maxPage > pConf.smallThreshold) {
      links.push(renderLink(maxPage - 1, {
        class: 'pagination-last',
        disabled: !pConf.last,
        key: 'l',
        label: '\u00BB'
      }));
    }

    return links;
  };

  /*
  opts: {
    disabled: true, // defaults to false if undefined
    key: string, // used for the... key
    label: 'string', // defaults to page number if undefined
  }
  */
  const renderLink = (toPage, opts = {}) => {
    const key = opts.key ? opts.key : toPage;
    const label = opts.label ? opts.label : `${toPage + 1}`;

    const active = !!(toPage === page);

    return !active ?
      (
        <LinkContainer key={`pl_${key}`} to={`${baseUrl}?page=${toPage}`}>
          <RBPagination.Item
            active={active}
            disabled={opts.disabled || false}
          >{label}</RBPagination.Item>
        </LinkContainer>
      ) :
      <RBPagination.Item
        key={`pl_${key}`}
        active={active}
        disabled={opts.disabled || false}
      >{label}</RBPagination.Item>;
  };

  // Calculated and render pagination links once for these page/maxPage values
  const links = useMemo(() => {
    // Get pagination config
    const pConf = calcPagination(page, maxPage, maxLinksPerSide);

    return renderLinks(pConf, maxPage);
  }, [page, maxPage]);

  return (
    <RBPagination>
      {/* {renderLinks(pConf, maxPage)} */}
      {links}
    </RBPagination>
  );
};
