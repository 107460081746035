import React from 'react';
import { Alert, Form, Button, Card, Container, Row, Col } from 'react-bootstrap';
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { Link } from "react-router-dom";

import { logIn } from '../store/actions';

export const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    clearErrors,
    control,
    handleSubmit,
    setError,
    watch,
    formState: { errors }
  } = useForm();

  // With have onSubmit and onSubmitForm below to allow manual clearErrors()
  // as react-hook-form requires manual clearing for general form errors.
  const onSubmit = data => {
    return dispatch(logIn(data))
      .unwrap()
      .then(() => {
        navigate('/');
      })
      .catch(err => {
        setError(
          err.meta && err.meta.field ? err.meta.field : 'form',
          {
            type: 'server',
            message: err.error
          }
        );
      });
  };
  const onSubmitForm = e => {
    clearErrors();
    return handleSubmit(onSubmit)(e);
  };

  return (
    <Container className="mt-4">
      <Row><Col md={{ span: 4, offset: 4 }}>
        <h1 className="mb-4">Log In</h1>

        <Form onSubmit={onSubmitForm}>

          {
            errors.form &&
            <Alert variant="danger">
              {errors.form?.message}
            </Alert>
          }

          <Form.Group className="mb-3">
            {/* <Form.Label>Email address</Form.Label> */}

            <Controller
              defaultValue=""
              control={control}
              name="email"
              render={({
                field: { onBlur, onChange, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState
              }) => (<Form.Control
                onBlur={onBlur}
                onChange={onChange}
                isInvalid={error}
                placeholder="Email address"
                ref={ref}
                type="email"
              />)}
              rules={{
                required: {
                  message: 'Please enter your login email address.',
                  value: true
                }
              }}
            />

            {errors.email && <Form.Control.Feedback type="invalid">
              {errors.email?.message}
            </Form.Control.Feedback>}
          </Form.Group>

          <Form.Group className="mb-3">
            {/* <Form.Label>Password</Form.Label> */}

            <Controller
              defaultValue=""
              control={control}
              name="password"
              render={({
                field: { onBlur, onChange, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState
              }) => (<Form.Control
                onBlur={onBlur}
                onChange={onChange}
                isInvalid={error}
                placeholder="Password"
                ref={ref}
                type="password"
              />)}
              rules={{
                maxLength: {
                  message: 'Invalid password length.',
                  value: 20
                },
                minLength: {
                  message: 'Invalid password length.',
                  value: 8
                },
                required: {
                  message: 'Please enter your login password.',
                  value: true
                }
              }}
            />

            {errors.password && <Form.Control.Feedback type="invalid">
              {errors.password?.message}
            </Form.Control.Feedback>}
          </Form.Group>

          <Button variant="primary" type="submit">Log In</Button>

        </Form>
      </Col></Row>
    </Container>
  );
};
