import React from 'react';

import Stack from 'react-bootstrap/Stack';

export const FauxCheckBox = ({ active, label, classes = {} }) => {
  const containerClass = classes.container || {};
  return (
    <Stack className={containerClass} direction="horizontal" gap={2}>
      {active && <i className="bi-check-square-fill text-primary"></i>}
      {!active && <i className="bi-square"></i>}
      <div>{label}</div>
    </Stack>
  );
};
