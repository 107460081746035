import React from 'react';
import Badge from 'react-bootstrap/Badge';

export const EnquiryStatusPill = ({ label, status }) => {
  let styleName;

  switch (status) {
    case 'open':
      styleName = 'success';
      break;
    case 'pending':
      styleName = 'danger';
      break;
    case 'closed':
      styleName = 'light';
      break;
  }

  return <Badge pill bg={styleName}>{label}</Badge>;
};
