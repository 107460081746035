'use strict';

import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiGet, apiPost } from '../../../lib/util/api';

// Load listings
export const loadListings = createAsyncThunk(
  'myListings/loadAll',
  async (opts = {}, { rejectWithValue }) => {
    try {
      // Send the request
      const response = await apiGet('/cp-api/my-listings');
      return response.data;
    }
    catch (err) {
      return rejectWithValue(err);
    }
  }
);

// Load a selected listing
export const loadListing = createAsyncThunk(
  'myListings/loadSelected',
  async (opts = {}, { rejectWithValue }) => {
    let { id } = opts;

    try {
      // Send the request
      const response = await apiGet(`/cp-api/my-listings/${id}`);
      return response.data;
    }
    catch (err) {
      throw rejectWithValue(err);
    }
  }
);

// Submit listing changes
export const submitListing = createAsyncThunk(
  'myListings/submit',
  async (opts = {}, { getState, rejectWithValue, requestId }) => {
    let { id, info, phoneNumber, ticketUrl } = opts;

    info = info !== '' ? info : undefined;
    phoneNumber = phoneNumber !== '' ? phoneNumber : undefined;
    ticketUrl = ticketUrl !== '' ? ticketUrl : undefined;

    const { currentRequestId, isSubmitting } = getState().myListings;
    if (!isSubmitting || requestId !== currentRequestId) {
      return;
    }

    try {
      const response = await apiPost(
        `/cp-api/my-listings/${id}`,
        { data: { info, phoneNumber, ticketUrl } }
      );

      return response.data;
    }
    catch (err) {
      throw rejectWithValue(err);
    }
  }
);


// === Flash Deals ===

// Load Flash Deals list
export const loadFlashDealsList = createAsyncThunk(
  'flashDeals/loadAll',
  async (opts = {}, { rejectWithValue }) => {
    try {
      // Send the request
      const response = await apiGet('/cp-api/my-listings/flash-deals');

      if (response.status === 200) {
        return response.data;
      }
      throw { code: response.status };
    }
    catch (err) {
      return rejectWithValue(err);
    }
  }
);

// Load Flash Deals for edit form
export const loadFlashDeal = createAsyncThunk(
  'flashDeals/load',
  async (opts = {}, { rejectWithValue }) => {
    let { id } = opts;

    try {
      // Send the request
      const response = await apiGet(`/cp-api/my-listings/flash-deals/${id}`);
      return response.data;
    }
    catch (err) {
      return rejectWithValue(err);
    }
  }
);

// Submit Flash Deal for approval
export const submitNewFlashDeal = createAsyncThunk(
  'flashDeals/submitNew',
  async (opts = {}, { getState, rejectWithValue, requestId }) => {
    let { from, to, summary, details } = opts;
    // console.log('flashDeals/submitNew action!', from, to, summary);

    const {
      currentRequestId,
      flashDealListingAppId,
      flashDealListingId,
      isSubmitting
    } = getState().myListings;
    if (
      !isSubmitting ||
      requestId !== currentRequestId ||
      !flashDealListingAppId ||
      !flashDealListingId
    ) {
      return;
    }

    try {
      const response = await apiPost(
        '/cp-api/my-listings/flash-deals/new',
        {
          data: {
            appId: flashDealListingAppId,
            listingId: flashDealListingId,
            from,
            to,
            summary,
            details
          }
        }
      );

      return response.data;
    }
    catch (err) {
      throw rejectWithValue(err);
    }
  }
);

// Submit Flash Deal edits
export const submitUpdatedFlashDeal = createAsyncThunk(
  'flashDeals/submitChanges',
  async (opts = {}, { getState, rejectWithValue, requestId }) => {
    const data = (({ from, to, details, summary }) =>
      ({ from, to, details, summary }))(opts);
    // console.log('flashDeals/submitChanges action!', data);

    const {
      currentRequestId,
      flashDealListingAppId,
      flashDealListingId,
      isSubmitting,
      selectedFlashDealId
    } = getState().myListings;
    if (
      !isSubmitting ||
      requestId !== currentRequestId ||
      !flashDealListingId
    ) {
      return;
    }

    try {
      const response = await apiPost(
        `/cp-api/my-listings/flash-deals/${selectedFlashDealId}`,
        { data: Object.assign(data, {
          appId: flashDealListingAppId,
          listingId: flashDealListingId
        }) }
      );

      return response.data;
    }
    catch (err) {
      throw rejectWithValue(err);
    }
  }
);

// Cancel Flash Deal
export const submitCancelFlashDeal = createAsyncThunk(
  'flashDeals/submitCancel',
  async (opts = {}, { getState, rejectWithValue, requestId }) => {
    // console.log('CANCEL ACTION', reason);

    const {
      currentRequestId,
      isSubmitting,
      selectedFlashDealId
    } = getState().moderate;
    if (
      !isSubmitting ||
      requestId !== currentRequestId
    ) {
      return;
    }

    try {
      const response = await apiPost(
        `/cp-api/my-listings/flash-deals/${selectedFlashDealId}/cancel`
      );

      return response.data;
    }
    catch (err) {
      throw rejectWithValue(err);
    }
  }
);

// // Get a new unique code
// export const getFlashDealCode = createAsyncThunk(
//   'myListings/getFlashDealCode',
//   async (opts = {}, { getState, rejectWithValue, requestId }) => {
//     let { id } = opts;

//     try {
//       // Send the request
//       const response =
//         await apiGet('/cp-api/my-listings/get-new-flash-deal-code');
//       return response.data;
//     }
//     catch (err) {
//       throw rejectWithValue(err);
//     }
//   }
// );