import React from 'react';

// Bootstrap
import Table from 'react-bootstrap/Table';

export const EmbeddedTable = (results = null) => {
  return (
    <Table striped borderless hover>
      <tbody>
        {results && <tr><td>

        </td></tr>}
      </tbody>
    </Table>
  );
};
