'use strict';

import { createSlice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import { logIn, logOut } from '../../auth/store/actions';
import { loadEnquiries, loadEnquiry, updateEnquiryStatus } from './actions';

// Import enq statuses
import { enquiriesPerPage, enquiryStatuses } from '../../../../common/config';

// Get the default enquiry statuses
const getDefaultEnquiryStatuses = () => enquiryStatuses.map(s => ({
  ...s,
  active: true
}));

const initialState = {
  appsForFilter: [],
  isLoadingPage: false,
  isLoadingSelected: false,
  enquiries: [],
  numTotal: 0,
  perPage: 0,
  selectedEnquiry: null,
  selectedEnquiryId: null,
  statusesForFilter: getDefaultEnquiryStatuses()
};

// Common log out callback
const commonLogOutCallback = (state, action) => {
  return { ...initialState };
};

// Created enquiries store slice
export const enquiriesSlice = createSlice({
  name: 'enquiries',

  initialState,

  reducers: {
    // Select enquiry
    selectEnquiry: (state, action) => {
      state.selectedEnquiry = null;
      state.selectedEnquiryId = action.payload.id;
    },

    // Update app filter
    setAppFilter: (state, action) => {
      const { id, appFilterState } = action.payload;
      const thisApp = state.appsForFilter.find(a => a.id === id);
      if (thisApp) {
        thisApp.active = !!appFilterState;
        state.appsForFilter = [...state.appsForFilter];
      }
    },

    // Update status filter
    setStatusFilter: (state, action) => {
      const { id, statusFilterState } = action.payload;
      const thisStatus = state.statusesForFilter.find(s => s.id === id);
      if (thisStatus) {
        thisStatus.active = !!statusFilterState;
        state.statusesForFilter = [...state.statusesForFilter];
      }
    }
  },

  extraReducers: builder => {
    // Load enquiries PENDING
    builder.addCase(loadEnquiries.pending, state => {
      state.isLoadingPage = true;
      state.selectedEnquiryId = null;
    });
    // Load enquiries FULFILLED
    builder.addCase(loadEnquiries.fulfilled, (state, action) => {
      const { data = [], meta = {} } = action.payload;
    
      return {
        ...state,
        enquiries: data,
        isLoadingPage: false,
        numTotal: meta.numTotal || 0,
        perPage: meta.perPage || enquiriesPerPage
      };
    });
    // Load enquiries REJECTED
    builder.addCase(loadEnquiries.rejected, state => {
      return {
        ...state,
        enquiries: [],
        isLoadingPage: false,
        numTotal: 0,
        perPage: enquiriesPerPage
      };
    });

    // Load selected enquiry PENDING
    builder.addCase(loadEnquiry.pending, state => {
      state.isLoadingSelected = true;
    });
    // Load selected enquiry FULFILLED
    builder.addCase(loadEnquiry.fulfilled, (state, action) => {
      const { data = null } = action.payload;
    
      return {
        ...state,
        selectedEnquiry: data,
        isLoadingSelected: false
      };
    });
    // Load selected enquiry REJECTED
    builder.addCase(loadEnquiry.rejected, state => {
      return {
        ...state,
        selectedEnquiry: null,
        isLoadingSelected: false
      };
    });

    // Update enquiry status FULFILLED
    builder.addCase(updateEnquiryStatus.fulfilled, (state, action) => {
      const { data = null } = action.payload;
    
      return {
        ...state,
        selectedEnquiry: data
      };
    });
    // Update enquiry status REJECTED
    builder.addCase(updateEnquiryStatus.rejected, state => {
      return {
        ...state,
        selectedEnquiry: null
      };
    });

    // On log in FULFILLED
    builder.addCase(logIn.fulfilled, (state, action) => {
      state.appsForFilter = action.payload.data.allowedApps.map(app => {
        return {
          active: true,
          id: app.appId,
          label: app.name
        };
      });
    });

    // Log out fulfilled or rejected due to error - reset state
    builder.addCase(logOut.fulfilled, commonLogOutCallback);
    builder.addCase(logOut.rejected, commonLogOutCallback);
  }
});

export const whitelist = [
  'appsForFilter',
  'enquiries',
  'numTotal',
  'perPage',
  'selectedEnquiry',
  'selectedEnquiryId',
  'statusesForFilter'
];

export const { selectEnquiry, setAppFilter, setStatusFilter } = enquiriesSlice.actions;
export const reducer = enquiriesSlice.reducer;
