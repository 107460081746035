import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
// import {
//   FLUSH,
//   PAUSE,
//   PERSIST,
//   persistReducer,
//   persistStore,
//   PURGE,
//   REGISTER,
//   REHYDRATE,
// } from 'redux-persist';

import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE
} from 'redux-persist/lib/constants';
import createTransform from 'redux-persist/lib/createTransform';
import persistStore from 'redux-persist/lib/persistStore';
import persistReducer from 'redux-persist/lib/persistReducer';
import storage from 'redux-persist/lib/storage';

const whitelists = {};
const initialStates = {};

// Import slice reducers
import {
  reducer as authReducer,
  whitelist as authWhitelist
} from '../../features/auth/store/slice';
whitelists.auth = authWhitelist;

import {
  reducer as enquiriesReducer,
  whitelist as enquiriesWhitelist
} from '../../features/enquiries/store/slice';
whitelists.enquiries = enquiriesWhitelist;

import {
  reducer as responsesReducer,
  whitelist as responsesWhitelist
} from '../../features/enquiries/responses/store/slice';
whitelists.responses = responsesWhitelist;

import {
  reducer as myListingsReducer,
  whitelist as myListingsWhitelist
} from '../../features/myListings/store/slice';
whitelists.myListings = myListingsWhitelist;

import {
  initialState as manageLoginsInitialState,
  reducer as manageLoginsReducer,
  whitelist as manageLoginsWhitelist
} from '../../features/manageLogins/store/slice';
whitelists.manageLogins = manageLoginsWhitelist;
initialStates.manageLogins = manageLoginsInitialState;

import {
  initialState as exploreInitialState,
  reducer as exploreReducer,
  whitelist as exploreWhitelist
} from '../../features/manage-explore/store/slice';
whitelists.explore = exploreWhitelist;
initialStates.explore = exploreInitialState;

import {
  reducer as moderateReducer,
  whitelist as moderateWhitelist
} from '../../features/moderate/store/slice';
whitelists.moderate = moderateWhitelist;

// Whitelist object key filtering
const whitelistObjectKeys = (key, obj, whitelist) => {
  const newObj = {};

  Object.keys(obj).forEach(objKey => {
    if (whitelist.indexOf(objKey) > -1) {
      newObj[objKey] = obj[objKey];
    }
  });

  return newObj;
};

// Whitelist transform
const whitelistTransform = createTransform(
  (state, key) => {
    return whitelists[key] ?
      whitelistObjectKeys(key, state, whitelists[key]) :
      state;
  },

  (state, key) => {    
    return initialStates[key] ?
      { ...initialStates[key], ...state } :
      state;
  }
);

// Persist the root reducer
const persistConfig = {
  key: 'root',
  storage,
  transforms: [whitelistTransform]
};
const rootReducer = combineReducers({
  auth: authReducer,
  enquiries: enquiriesReducer,
  explore: exploreReducer,
  manageLogins: manageLoginsReducer,
  moderate: moderateReducer,
  myListings: myListingsReducer,
  responses: responsesReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  })
});

// Persist the store
export const persistor = persistStore(store);
