import React, { useEffect } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';

// Bootstrap
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import Table from 'react-bootstrap/Table';

import { logOut } from '../../../auth/store/actions';
import { submitResponse } from '../store/actions';

export const ReplyForm = props => {
  const dispatch = useDispatch();
  const { isSubmitting, selectedEnquiryId } = props;
  const {
    clearErrors,
    control,
    handleSubmit,
    reset,
    setError,
    watch,
    formState: { errors, isSubmitSuccessful }
  } = useForm({
    defaultValues: { body: '' }
  });

  // Reset the form on success
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  // With have onSubmit and onSubmitForm below to allow manual clearErrors()
  // as react-hook-form requires manual clearing for general form errors.
  const onSubmit = data => {
    return dispatch(submitResponse({
      ...data,
      id: selectedEnquiryId
    }))
      .unwrap()
      .catch(err => {
        if (err && err?.code === 401) {
          return dispatch(logOut());
        }
        else {
          setError(
            err.meta && err.meta.field ? err.meta.field : 'form',
            {
              type: 'server',
              message: err.error || err.toString()
            }
          );
        }
      });
  };
  const onSubmitForm = e => {
    clearErrors();
    return handleSubmit(onSubmit)(e);
  };

  return (
    <>
      <div className="bg-secondary fs-5 mb-1 p-3 text-light">Reply</div>

      <div className="bg-white p-3">
        <Form onSubmit={onSubmitForm}>

          {
            errors.form &&
            <Alert variant="danger">
              {errors.form?.message}
            </Alert>
          }

          <Form.Group className="mb-3">
            <span className="fs-7 text-muted text-uppercase">
              Your response
            </span>

            <Controller
              defaultValue=""
              control={control}
              name={'body'}
              render={({
                field: { onBlur, onChange, ref, value },
                fieldState: { invalid, isTouched, isDirty, error },
                formState
              }) => (<Form.Control
                as="textarea"
                isInvalid={error}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                rows="5"
                value={value}
              />)}
              rules={{
                required: {
                  message: 'Please enter a reply.',
                  value: true
                }
              }}
            />

            {errors.body && <Form.Control.Feedback type="invalid">
              {errors.body?.message}
            </Form.Control.Feedback>}

            <Form.Text className="fs-7 text-muted">
              Make sure to check your response before sending as the user will
              be notified immediately.
            </Form.Text>
          </Form.Group>

          <div className="d-grid">
            <Button type="submit" variant="primary">Send Reply</Button>
          </div>

        </Form>
      </div>

    </>
  );
};

// onReplyEdit(evt) {
//   this.props.editReply(evt.target.value);
// }

// onSubmitReply(evt) {
//   const { currentReply, isSubmitting, messageId, submitReply } = this.props;

//   if (!isSubmitting && messageId && currentReply && currentReply !== '') {
//     return submitReply(messageId, currentReply);
//   }
// }
// }

// function mapStateToProps(state) {
//   const { selectedMsg } = state.messages;
//   const { currentReply, isSubmitting } = state.responses;

//   return {
//     currentReply,
//     isSubmitting,
//     messageId: (selectedMsg ? selectedMsg.id : null)
//   };
// }

// export default connect(
//   mapStateToProps,
//   { editReply, submitReply }
// )(ReplyForm);
