import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";

// Bootstrap
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import Table from 'react-bootstrap/Table';

// Components
import { EmbeddedTable }
  from '../../../lib/components/EmbeddedTable';

// Actions
import {
  loadLogin,
  resetPassword,
  searchExplore,
  submitLogin
} from '../store/actions';
import { logOut } from '../../auth/store/actions';
import {
  addExploreItem,
  clearExploreSearchResults,
  removeExploreItem,
  setAccessValue
} from '../store/slice';

// Util
import { devLog } from '../../../lib/util/devLog';

import { defaultAccess } from '../../../../common/config';

// Prep default values
const getDefaultValues = (login, accessForForm, appsForFilter) => {
  // Access defaults
  const accessDefaults = Object.fromEntries(
    Object.keys(defaultAccess).map(key => {
      if (!accessForForm[key]) {
        return [];
      }

      return [
        `access_${key}`,
        accessForForm[key]
      ];
    })
  );

  // Allowed Apps defaults
  const allowedAppsDefaults = Object.fromEntries(
    appsForFilter.map(app => [
      `allowedApps_${app.id}`,
      login?.appIds.some(appId => appId === app.id) || null
    ])
  );

  return login ?
    {
      email: login.email,
      name: login.name,
      ...accessDefaults,
      ...allowedAppsDefaults
    } :
    {};
};

export const ManageLoginForm = () => {
  const dispatch = useDispatch();
  const exploreSearchRef = useRef();

  const accessForForm = useSelector(
    ({ manageLogins }) => manageLogins.accessForForm
  );
  const appsForFilter = useSelector(
    ({ manageLogins }) => manageLogins.appsForFilter
  );
  const exploreSearchResults = useSelector(
    ({ manageLogins }) => manageLogins.exploreSearchResults
  );
  const isLoadingSelected = useSelector(
    ({ manageLogins }) => manageLogins.isLoadingSelected
  );
  const login = useSelector(({ manageLogins }) => manageLogins.selectedLogin);
  const selectedLoginId = useSelector(
    ({ manageLogins }) => manageLogins.selectedLoginId
  );
  const isSubmitting = useSelector(
    ({ manageLogins }) => manageLogins.isSubmitting
  );

  const hasExploreItems = login ?
    login.exploreItems && login.exploreItems.length > 0 :
    false;

  // Set login exploreIds
  const [exploreIds, setExploreIds] = useState([]);

  // Get form defaults
  let defaultValues = getDefaultValues(
    login || null,
    accessForForm,
    appsForFilter
  );

  // TODO IF THERE IS NO SELECTEDLOGINID GET THE QUERY PARAM AND SET IT - 
  // the rest of the code should take care of loading the login
  
  useEffect(() => {
    if (selectedLoginId && !login && !isLoadingSelected) {
      dispatch(loadLogin({ id: selectedLoginId }));
    }
    else if (login && login.exploreItems) {
      setExploreIds(login.exploreItems.map(item => item.id));
    }
  }, [login]);
  
  const {
    clearErrors,
    control,
    getValues,
    handleSubmit,
    reset,
    setError,
    setValue,
    watch,
    formState: { errors, isSubmitSuccessful }
  } = useForm({
    defaultValues
  });

  // Reset the form on login load
  useEffect(() => {
    if (login) {
      reset(defaultValues);
    }
  }, [login, reset]);

  if (isLoadingSelected === true || !login) {
    return null;
  }

  // Extract access from the separate access fields
  const getAccessFromFields = data => {
    const access = {};

    const fieldKeys = Object.keys(data);
    for (let i = 0; i < fieldKeys.length; i++) {
      if (fieldKeys[i].indexOf('access_') === 0) {
        access[fieldKeys[i].slice(7)] = data[fieldKeys[i]] === true ?
          true : false;
      }
    }

    return access;
  };

  // Extract appIds from the separate allowed apps fields
  const getAppIdsFromFields = data => {
    const appIds = [];
    const fieldKeys = Object.keys(data);
    for (let i = 0; i < fieldKeys.length; i++) {
      if (
        fieldKeys[i].indexOf('allowedApps_') === 0 &&
        data[fieldKeys[i]] === true
      ) {
        appIds.push(fieldKeys[i].slice(12));
      }
    }

    return appIds.join(',');
  };

  // Event handlers

  // With have onSubmit and onSubmitForm below to allow manual clearErrors()
  // as react-hook-form requires manual clearing for general form errors.
  const onSubmit = data => {
    if (isSubmitting) {
      return;
    }

    // Get access settings
    const access = getAccessFromFields(data);

    // Get allowed app IDs
    const appIds = getAppIdsFromFields(data);

    // Extract data from formdata object, and add processed fields
    const submitData = (({ email, name }) =>
      ({ email, name }))(data);

    submitData.access = access;
    submitData.appIds = appIds;
    submitData.exploreIds = exploreIds.join(',');

    return dispatch(submitLogin({
      ...submitData,
      id: selectedLoginId
    }))
      .unwrap()
      .then(({ data }) => {
        reset(getDefaultValues(data || null, accessForForm, appsForFilter));
      })
      .catch(err => {
        if (err && err?.code === 401) {
          return dispatch(logOut());
        }
        else {
          setError(
            err.meta && err.meta.field ? err.meta.field : 'form',
            {
              type: 'server',
              message: err.error || err.toString()
            }
          );
        }
      });
  };
  const onSubmitForm = e => {
    clearErrors();
    return handleSubmit(onSubmit)(e);
  };

  // On click search Explore Locations
  const dispatchSearch = () => {
    dispatch(searchExplore({
      appIds: getAppIdsFromFields(getValues()),
      terms: exploreSearchRef.current.value
    }))
      .unwrap()
      .catch(err => {
        devLog('Explore Search Error:', err.message || err);
      });
  };
  const onKeyDownSearch = evt => {
    if (evt.keyCode === 13) {
      evt.preventDefault();
      dispatchSearch();
    }
  };
  const onClickSearch = () => {
    dispatchSearch();
  };

  // On click clear search
  const onClickClearSearch = () => {
    exploreSearchRef.current.value = '';
  };

  // On click add search result to login exploreIds
  const onClickSearchResult = result => {
    if (exploreIds.indexOf(result.id) < 0) {
      setExploreIds(setExploreIds([...exploreIds, result.id]));
    }
    dispatch(addExploreItem({ item: result }));
    dispatch(clearExploreSearchResults());
    exploreSearchRef.current.value = '';
  };

  // On click remove associated Explore item
  const onClickRemoveExplore = id => {
    setExploreIds(exploreIds.filter(eId => eId !== id));
    dispatch(removeExploreItem({ id }))
  };

  // On click reset password
  const onClickResetPassword = e => {
    if (login && window.confirm(
      'Are you sure you want to reset the password for this login?' +
      '\n\nThe new password will be generated automatically and sent to ' +
      'their registered email address. The old password will be overwritten ' +
      'and, if they are currently logged in, they will need to log in again.' +
      '\n\nThis will happen immediately when you click OK.'
    )) {
      dispatch(resetPassword({ id: login.id }))
    }
  };

  // / Event handlers

  return (
    <>
      <h1 className="mb-4">
        <Link className="text-muted" to="/manage-logins">Manage Logins</Link>
        {' / '}
        {login.name}
      </h1>

      <Form onSubmit={onSubmitForm}>

        {isSubmitSuccessful && <Alert variant="success">
          Changes saved!
        </Alert>}

        {errors.form && <Alert variant="danger">
          {errors.form?.message}
        </Alert>}

        <Row className="mb-3">
          {/* Login details */}
          <Col className="mb-3" lg={8} sm={12}>
            <Container className="bg-white p-3">
              <Row>

                {/* LEFT COLUMN */}
                <Col className="mb-3" lg={6}>

                  {/* Email address */}
                  <Form.Group className="mb-3">
                    <span className="fs-7 text-muted text-uppercase">
                      Email Address
                    </span>

                    <Controller
                      defaultValue=""
                      control={control}
                      name="email"
                      render={({
                        field: { onBlur, onChange, ref, value },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState
                      }) => (<Form.Control
                        onBlur={onBlur}
                        onChange={onChange}
                        isInvalid={error}
                        placeholder="eg. yourname@mailservice.com"
                        ref={ref}
                        type="email"
                        value={value}
                      />)}
                      rules={{ required: true }}
                    />

                    {errors.email && <Form.Control.Feedback type="invalid">
                      {errors.email?.message}
                    </Form.Control.Feedback>}

                    <Form.Text className="fs-7 text-muted">
                      Used to log in to the Control Panel, to receive important
                      alerts, and receive password changes.
                    </Form.Text>
                  </Form.Group>

                  {/* Name */}
                  <Form.Group className="mb-3">
                    <span className="fs-7 text-muted text-uppercase">
                      Name
                    </span>

                    <Controller
                      defaultValue=""
                      control={control}
                      name="name"
                      render={({
                        field: { onBlur, onChange, ref, value },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState
                      }) => (<Form.Control
                        onBlur={onBlur}
                        onChange={onChange}
                        isInvalid={error}
                        placeholder="eg. Dave / The Dave Hotel"
                        ref={ref}
                        value={value}
                      />)}
                      rules={{ required: true }}
                    />

                    {errors.name && <Form.Control.Feedback type="invalid">
                      {errors.name?.message}
                    </Form.Control.Feedback>}

                    <Form.Text className="fs-7 text-muted">
                      The name used when they reply to Enquiries. The name can
                      either represent a person or organisation, depending who
                      is using this login.
                    </Form.Text>
                  </Form.Group>

                  {/* Access Options */}
                  <Form.Group className="mb-3">
                    <span className="fs-7 text-muted text-uppercase">
                      Control Panel Access
                    </span>

                    {Object.keys(defaultAccess).map(key => <Controller
                      control={control}
                      defaultValue={false}
                      key={`access_${key}`}
                      name={`access_${key}`}
                      render={({
                        field: { name, ref, value }
                      }) => {
                        return (
                          <Stack
                            className="my-1"
                            direction="horizontal"
                            gap={2}

                            onClick={() => {
                              dispatch(setAccessValue({
                                key,
                                value: value === true ? false : true
                              }));
                              setValue(
                                `access_${key}`,
                                value === true ? false : true
                              )
                            }}
                            ref={ref}
                            role="button"
                          >
                            {value && <i className="bi-check-square-fill text-primary" />}
                            {!value && <i className="bi-square" />}
                            <div>{defaultAccess[key].label}</div>
                          </Stack>
                        );
                      }}
                    />)}

                    <Form.Text className="fs-7 text-muted">
                      The control panel features this login can access.
                    </Form.Text>
                  </Form.Group>

                </Col>

                {/* RIGHT COLUMN */}
                <Col lg={6}>

                  {/* Allowed Apps */}
                  <Form.Group className="mb-3">
                    <span className="fs-7 text-muted text-uppercase">
                      Allowed Apps
                    </span>

                    {appsForFilter.map(app => <Controller
                      control={control}
                      defaultValue={false}
                      key={`allowedApps_${app.id}`}
                      name={`allowedApps_${app.id}`}
                      render={({
                        field: { name, ref, value }
                      }) => {
                        return (
                          <Stack
                            className="my-1"
                            direction="horizontal"
                            gap={2}

                            onClick={() => {
                              setValue(
                                `allowedApps_${app.id}`,
                                value === true ? false : true
                              )
                            }}
                            ref={ref}
                            role="button"
                          >
                            {value && <i className="bi-check-square-fill text-primary" />}
                            {!value && <i className="bi-square" />}
                            <div>{app.label}</div>
                          </Stack>
                        );
                      }}
                    />)}

                    <Form.Text className="fs-7 text-muted">
                      The app(s) this login can access data for.
                    </Form.Text>
                  </Form.Group>

                  {/* Search Explore Locations */}
                  <Form.Group>
                    <span className="fs-7 text-muted text-uppercase">
                      Search Explore Locations
                    </span>

                    <InputGroup>
                      <Form.Control
                        aria-label="Search Explore Locations"
                        onKeyDown={onKeyDownSearch}
                        placeholder="eg. Hotel California"
                        ref={exploreSearchRef}
                      />

                      <Button onClick={onClickSearch} variant="primary">
                        <i className="bi-search" />
                      </Button>

                      <Button onClick={onClickClearSearch} variant="secondary">
                        <i className="bi-x-lg" />
                      </Button>
                    </InputGroup>

                    {/* Table of Search Results */}
                    <Table striped borderless hover>
                      <tbody>
                        {exploreSearchResults &&
                          exploreSearchResults.map(result =>
                            <tr key={`ml_expsr_${result.id}`}><td>
                              <div className="d-flex align-items-center">
                                <Stack className="flex-grow" gap={0}>
                                  <span className="text-bold">
                                    {result.name}
                                  </span>
                                  <span className="fs-7 text-muted">
                                    {result.appName}
                                  </span>
                                </Stack>
                                <Button
                                  onClick={() => onClickSearchResult(result)}
                                  type="button"
                                >
                                  <i className="bi-plus-lg" />
                                </Button>
                              </div>
                            </td></tr>)
                        }
                      </tbody>
                    </Table>

                    {errors.searchExplore &&
                      <Form.Control.Feedback type="invalid">
                        {errors.searchExplore?.message}
                      </Form.Control.Feedback>}
                  </Form.Group>

                  {/* Associated Explore Locations */}
                  <span className="fs-7 text-muted text-uppercase">
                    Associated Explore Locations
                  </span>

                  <Table className="mb-1" striped borderless hover>
                    <tbody>
                      {!hasExploreItems && <tr>
                        <td className="text-muted">
                          No associated Explore locations
                        </td>
                      </tr>}
                      {hasExploreItems &&
                        login.exploreItems.map(item =>
                          <tr key={`ml_expcrnt_${item.id}`}><td>
                            <div className="d-flex align-items-center">
                              <Stack className="flex-grow" gap={0}>
                                <span className="text-bold">
                                  {item.name}
                                </span>
                                <span className="fs-7 text-muted">
                                  {item.appName}
                                </span>
                              </Stack>
                              <Button
                                onClick={() => onClickRemoveExplore(item.id)}
                                type="button"
                                variant="danger"
                              >
                                <i className="bi-dash-lg" />
                              </Button>
                            </div>
                          </td></tr>)
                      }
                    </tbody>
                  </Table>

                  <span className="fs-7 text-muted">
                    Search above to find and add a location. Click "-" to remove
                    the associated Explore location. Nothing is permanent until
                    you Save Changes.
                  </span>

                </Col>
              </Row>
            </Container>
          </Col>

          {/* Manage Logins sidebar */}
          <Col lg={4}>
            <Stack gap={2}>
              <div className="bg-white p-3 mb-2">
                <Button
                  className="w-100"
                  type="submit"
                  variant="primary"
                >
                  Save Changes
                </Button>
              </div>
              <div className="bg-white p-3 pb-3 mb-2">
                <Button
                  className="mb-2 w-100"
                  onClick={onClickResetPassword}
                  variant="danger">
                  Reset Password
                </Button>
                <Form.Text className="fs-7 text-muted">
                  Sends an email to the login's email address containing a new
                  password. The old password is overwritten immediately so make
                  sure you know this is needed. For security, passwords cannot
                  be manually set, or read by anyone but the email recipient.
                </Form.Text>
              </div>
            </Stack>
          </Col>
        </Row>

      </Form>
    </>
  );
};
