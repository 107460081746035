import React from 'react';
import { Outlet } from "react-router-dom";

// Bootstrap
import Container from 'react-bootstrap/Container';

export const Layout = () => {
  return (
    <Container className="mt-4">
      <Outlet />
    </Container>
  );
};
