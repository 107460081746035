'use strict';

import { createSlice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import { logOut } from '../../auth/store/actions';
import {
  loadFlashDeal,
  loadFlashDealsList,
  loadListing,
  loadListings,
  submitCancelFlashDeal,
  submitListing,
  submitNewFlashDeal,
  submitUpdatedFlashDeal
} from './actions';

const initialState = {
  currentRequestId: null,
  isLoadingListings: false,
  isLoadingSelected: false,
  isSubmitting: false,
  selectedFlashDeal: null,
  selectedFlashDealId: null,
  flashDealsList: [],
  flashDealListingAppId: null,
  flashDealListingId: null,
  flashDealListingName: null,
  listings: [],
  selectedListing: null,
  selectedListingId: null
};

// Common log out callback
const commonLogOutCallback = (state, action) => {
  return { ...initialState };
};

// Created myListing store slice
export const myListingSlice = createSlice({
  name: 'myListings',

  initialState,

  reducers: {
    // Select listing
    selectListing: (state, action) => {
      state.selectedListing = null;
      state.selectedListingId = action.payload.id;
    },

    // Set Flash Deal listing
    selectFlashDeal: (state, action) => {
      // console.log('selectFlashDeal', action.payload);
      state.flashDealListingAppId = action.payload.appId;
      state.flashDealListingId = action.payload.id;
      state.flashDealListingName = action.payload.name;
      state.selectedFlashDeal = null;
      state.selectedFlashDealId = action.payload.flashDealId;
    }
  },

  extraReducers: builder => {
    // Load listings PENDING
    builder.addCase(loadListings.pending, state => {
      state.isLoadingListings = true;
      state.selectedListingId = null;
    });
    // Load listings FULFILLED
    builder.addCase(loadListings.fulfilled, (state, action) => {
      const { data = [], meta = {} } = action.payload;

      return {
        ...state,
        listings: data,
        // listingsFlashDeals: data.flashDeals,
        isLoadingListings: false
      };
    });
    // Load listings REJECTED
    builder.addCase(loadListings.rejected, state => {
      return {
        ...state,
        listings: [],
        isLoadingListings: false
      };
    });

    // Load selected listing PENDING
    builder.addCase(loadListing.pending, state => {
      state.isLoadingSelected = true;
      state.selectedListing = null;
    });
    // Load selected listing FULFILLED
    builder.addCase(loadListing.fulfilled, (state, action) => {
      const { data = null } = action.payload;

      return {
        ...state,
        selectedListing: data,
        isLoadingSelected: false
      };
    });
    // Load selected listing REJECTED
    builder.addCase(loadListing.rejected, state => {
      return {
        ...state,
        selectedListing: null,
        isLoadingSelected: false
      };
    });

    // Submit listing PENDING
    builder.addCase(submitListing.pending, (state, action) => {
      if (!state.isSubmitting) {
        // console.log('PENDING', state.isSubmitting, state.currentRequestId, action.meta.requestId);
        state.currentRequestId = action.meta.requestId;
        state.isSubmitting = true;
      }
    });
    // Submit listing FULFILLED
    builder.addCase(submitListing.fulfilled, (state, action) => {
      if (
        state.isSubmitting &&
        state.currentRequestId === action.meta.requestId
      ) {
        return {
          ...state,

          // Resets
          currentRequestId: undefined,
          isSubmitting: false
        };
      }
    });
    // Submit listing REJECTED
    builder.addCase(submitListing.rejected, (state, action) => {
      if (
        state.isSubmitting &&
        state.currentRequestId === action.meta.requestId
      ) {
        state.isSubmitting = false;
        state.currentRequestId = undefined;
      }
    });


    // === Flash Deals ===

    // Load FD listings PENDING
    builder.addCase(loadFlashDealsList.pending, state => {
      state.isLoadingListings = true;
      state.selectedListingId = null;
    });
    // Load FD listings FULFILLED
    builder.addCase(loadFlashDealsList.fulfilled, (state, action) => {
      const { data = [], meta = {} } = action.payload;

      return {
        ...state,
        flashDealsList: data,
        // listingsFlashDeals: data.flashDeals,
        isLoadingListings: false
      };
    });
    // Load FD listings REJECTED
    builder.addCase(loadFlashDealsList.rejected, state => {
      return {
        ...state,
        flashDealsList: [],
        isLoadingListings: false
      };
    });

    // Load selected FD PENDING
    builder.addCase(loadFlashDeal.pending, state => {
      state.flashDealListingName = null;
      state.isLoadingSelected = true;
      state.selectedFlashDeal = null;
    });
    // Load selected FD FULFILLED
    builder.addCase(loadFlashDeal.fulfilled, (state, action) => {
      const { data = null } = action.payload;

      return {
        ...state,
        flashDealListingName: data.name || '',
        selectedFlashDeal: data,
        isLoadingSelected: false
      };
    });
    // Load selected FD REJECTED
    builder.addCase(loadFlashDeal.rejected, state => {
      return {
        ...state,
        flashDealListingName: null,
        selectedFlashDeal: null,
        isLoadingSelected: false
      };
    });

    // Submit FD PENDING
    builder.addCase(submitNewFlashDeal.pending, (state, action) => {
      if (!state.isSubmitting) {
        // console.log('PENDING', state.isSubmitting, state.currentRequestId, action.meta.requestId);
        state.currentRequestId = action.meta.requestId;
        state.isSubmitting = true;
      }
    });
    // Submit FD FULFILLED
    builder.addCase(submitNewFlashDeal.fulfilled, (state, action) => {
      if (
        state.isSubmitting &&
        state.currentRequestId === action.meta.requestId
      ) {
        return {
          ...state,

          // Resets
          currentRequestId: undefined,
          isSubmitting: false
        };
      }
    });
    // Submit FD REJECTED
    builder.addCase(submitNewFlashDeal.rejected, (state, action) => {
      if (
        state.isSubmitting &&
        state.currentRequestId === action.meta.requestId
      ) {
        state.isSubmitting = false;
        state.currentRequestId = undefined;
      }
    });

    // Submit FD edits PENDING
    builder.addCase(submitUpdatedFlashDeal.pending, (state, action) => {
      if (!state.isSubmitting) {
        state.currentRequestId = action.meta.requestId;
        state.isSubmitting = true;
      }
    });
    // Submit FD edits FULFILLED
    builder.addCase(submitUpdatedFlashDeal.fulfilled, (state, action) => {
      if (
        state.isSubmitting &&
        state.currentRequestId === action.meta.requestId
      ) {
        return {
          ...state,

          // Resets
          currentRequestId: undefined,
          isSubmitting: false
        };
      }
    });
    // Submit FD edits REJECTED
    builder.addCase(submitUpdatedFlashDeal.rejected, (state, action) => {
      if (
        state.isSubmitting &&
        state.currentRequestId === action.meta.requestId
      ) {
        state.isSubmitting = false;
        state.currentRequestId = undefined;
      }
    });

    // Submit FD cancel PENDING
    builder.addCase(submitCancelFlashDeal.pending, (state, action) => {
      if (!state.isSubmitting) {
        state.currentRequestId = action.meta.requestId;
        state.isSubmitting = true;
      }
    });
    // Submit FD cancel FULFILLED
    builder.addCase(submitCancelFlashDeal.fulfilled, (state, action) => {
      if (
        state.isSubmitting &&
        state.currentRequestId === action.meta.requestId
      ) {
        return {
          ...state,
          selectedFlashDeal: action.payload.data,

          // Resets
          currentRequestId: undefined,
          isSubmitting: false
        };
      }
    });
    // Submit FD cancel REJECTED
    builder.addCase(submitCancelFlashDeal.rejected, (state, action) => {
      if (
        state.isSubmitting &&
        state.currentRequestId === action.meta.requestId
      ) {
        state.isSubmitting = false;
        state.currentRequestId = undefined;
      }
    });

    // Log out fulfilled or rejected due to error - reset state
    builder.addCase(logOut.fulfilled, commonLogOutCallback);
    builder.addCase(logOut.rejected, commonLogOutCallback);
  }
});

export const whitelist = [
  'listings',
  'flashDealListingAppId',
  'flashDealListingId',
  'flashDealListingName',
  'selectedFlashDeal',
  'selectedFlashDealId',
  'selectedListing',
  'selectedListingId'
];

export const { selectListing, selectFlashDeal } = myListingSlice.actions;
export const reducer = myListingSlice.reducer;
