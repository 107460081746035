import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import TimeAgo from 'timeago-react';

// Bootstrap
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import Table from 'react-bootstrap/Table';

import { DropDownFilter } from '../../../lib/components/DropDownFilter';
import { Pagination } from '../../../lib/components/Pagination';
import { EnquiryStatusPill } from '../components/EnquiryStatusPill';

import { loadEnquiries } from '../store/actions';
import { logOut } from '../../auth/store/actions';
import { selectEnquiry, setAppFilter, setStatusFilter } from '../store/slice';

import { enquiriesPerPage } from '../../../../common/config';

export const List = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get('page') || 0;

  const access = useSelector(({ auth }) => auth.access);
  const isLoggedIn = useSelector(({ auth }) => auth.isLoggedIn);
  const appsForFilter = useSelector(({ enquiries }) => enquiries.appsForFilter);
  const enquiries = useSelector(({ enquiries }) => enquiries.enquiries);
  const isLoadingPage = useSelector(({ enquiries }) => enquiries.isLoadingPage);
  const numTotal = useSelector(({ enquiries }) => enquiries.numTotal);
  const perPage = useSelector(({ enquiries }) => enquiries.perPage);
  const statusesForFilter = useSelector(({ enquiries }) =>
    enquiries.statusesForFilter);

  const _loadEnquiries = ({ page }) => {
    return dispatch(loadEnquiries({ page }))
      .unwrap()
      .catch(err => {
        if (err && err?.code === 401) {
          return dispatch(logOut());
        }
      });
  };

  useEffect(() => {
    if (!isLoggedIn) {
      return navigate('/login', { replace: true });
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (access && access.enquiries !== true) {
      return navigate('/', { replace: true });
    }
  });

  useEffect(() => {
    _loadEnquiries({ page });
  }, [page]);

  // Event handlers

  const onChangeAppFilter = (id, appFilterState) => {
    dispatch(setAppFilter({ id, appFilterState }));
    _loadEnquiries({ page: 0 });
  };

  const onChangeStatusFilter = (id, statusFilterState) => {
    dispatch(setStatusFilter({ id, statusFilterState }));
    _loadEnquiries({ page: 0 });
  };

  const onClickSelectEnquiry = id => {
    dispatch(selectEnquiry({ id }));
  }

  // / Event handlers

  // Fragment render fns

  const renderAppFilter = () => {
    return <DropDownFilter
      label="Apps"
      onChange={onChangeAppFilter}
      options={appsForFilter}
    />;
  };

  const renderStatusFilter = () => {
    return <DropDownFilter
      label="Status"
      onChange={onChangeStatusFilter}
      options={statusesForFilter}
    />;
  };

  const renderNumResponses = numResponses => {
    switch (numResponses) {
      case 0:
        return 'no responses';

      case 1:
        return '1 response';

      default:
        return `${numResponses} responses`;
    }
  };

  const renderAppsSummary = apps => {
    let appsStr = 'None Selected';
    if (apps.length > 0) {
      appsStr = apps.filter(a => a.active).map(a => a.label).join(' / ');
    }
    return (<div>
      <span className="fw-bold text-white-50">Apps:</span> {appsStr}
    </div>);
  };

  const renderStatusSummary = statuses => {
    let statusesStr = 'None Selected';
    if (statuses.length > 0) {
      statusesStr = statuses.filter(s => s.active).map(s => s.label).join(' / ');
    }
    return (<div>
      <span className="fw-bold text-white-50">Statuses:</span> {statusesStr}
    </div>);
  };

  // / Fragment render fns

  return (
    <>
      <h1 className="mb-4">Enquiries</h1>

      <Stack className="mb-4" direction="horizontal" gap={3}>
        {renderAppFilter()}
        {renderStatusFilter()}
      </Stack>

      <div className="bg-secondary d-flex flex-row fs-7 justify-content-between mb-1 p-3 text-light">
        {renderAppsSummary(appsForFilter)}
        {renderStatusSummary(statusesForFilter)}
      </div>

      <Table borderless striped hover>
        <tbody>
          {!isLoadingPage && enquiries && enquiries.map(enquiry =>
            <tr key={`enqs_list_${enquiry.id}`}>
              <td className="p-0">
                <Link
                  className="d-block p-3 text-decoration-none"
                  to={`/enquiries/${enquiry.id}`}
                  onClick={() => onClickSelectEnquiry(enquiry.id)}
                >
                  <Stack
                    className="align-items-center text-body"
                    direction="horizontal"
                    gap="3"
                  >
                    <Stack gap={2}>
                      <div className="fs-6">
                        <span className="fw-bold">
                          {enquiry.contactMsgTypeLabel}
                        </span> re: {enquiry.targetName}
                      </div>
                      <div className="fs-7 text-muted">
                        <span className="fw-bold"> <TimeAgo
                          datetime={enquiry.createdAt}
                        /></span>
                        {` from ${enquiry.userName}, `}
                        {renderNumResponses(enquiry.numResponses)}
                      </div>
                    </Stack>

                    <span className="fs-5"><EnquiryStatusPill
                      label={enquiry.statusLabel}
                      status={enquiry.status}
                    /></span>
                  </Stack>
                </Link>
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <div className="d-flex justify-content-center">
        <Pagination
          baseUrl="/enquiries"
          maxLinksPerSide="3"
          numTotal={numTotal}
          page={page}
          perPage={enquiriesPerPage}
        />
      </div>
    </>
  );
};
