'use strict';

import { createSlice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import { logOut } from '../../../auth/store/actions';
import { loadResponses, submitResponse } from './actions';

// Shared reducer callback for load responses fulfilled and rejected
const handleLoadResponsesResult = (state, action) => {
  const { data = [], meta = {} } = action.payload;

  return {
    ...state,
    isLoadingResponses: false,
    numTotal: meta.numTotal || 0,
    responses: data
  };
};

const initialState = {
  currentRequestId: null,
  isLoadingResponses: false,
  isSubmitting: false,
  numTotal: 0,
  responses: []
};

// Created enquiries store slice
export const responsesSlice = createSlice({
  name: 'responses',

  initialState,

  extraReducers: builder => {
    // Load responses PENDING
    builder.addCase(loadResponses.pending, state => {
      state.isLoadingResponses = true;
    });
    // Load enquiries FULFILLED
    builder.addCase(loadResponses.fulfilled, handleLoadResponsesResult);
    // Load enquiries REJECTED
    builder.addCase(loadResponses.rejected, handleLoadResponsesResult);

    // Submit response PENDING
    builder.addCase(submitResponse.pending, (state, action) => {
      if (!state.isSubmitting) {
        state.currentRequestId = action.meta.requestId;
        state.isSubmitting = true;
      }
    });

    // Submit response FULFILLED
    builder.addCase(submitResponse.fulfilled, (state, action) => {
      const { requestId } = action.meta;

      if (state.isSubmitting && state.currentRequestId === requestId) {
        return {
          ...state,

          // Resets
          currentRequestId: undefined,
          isSubmitting: false,

          numTotal: action.payload.meta.numTotal
        };
      }
    });

    // Submit response REJECTED
    builder.addCase(submitResponse.rejected, (state, action) => {
      const { requestId } = action.meta;

      if (state.isSubmitting && state.currentRequestId === requestId) {
        state.isSubmitting = false;
        state.currentRequestId = undefined;
      }
    });

    // On log out FULFILLED
    builder.addCase(logOut.fulfilled, (state, action) => {
      return { ...initialState };
    });
  }
});

export const whitelist = [
  'numTotal',
  'responses'
];

export const reducer = responsesSlice.reducer;
