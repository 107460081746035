import React, { useState } from 'react';

// Bootstrap
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Stack from 'react-bootstrap/Stack';

import { FauxCheckBox } from './FauxCheckBox';

export const DropDownFilter = ({
  variant,
  isActive,
  label,
  onChange,
  options
}) => {
  const [filterOpen, setFilterOpen] = useState(isActive);

  const renderOptions = () => {
    return options.map(o => {
      return (
        <Dropdown.Item key={o.id} onClick={() => onChange(o.id, !o.active)}>
          <FauxCheckBox active={o.active} label={o.label} />
        </Dropdown.Item>
      );
    });
  }

  const toggleActive = val =>
    setFilterOpen(typeof val !== 'undefined' ? val : !filterOpen);

  return (
    <DropdownButton
      autoClose="outside"
      id="dropdown-basic-button"
      onClick={() => toggleActive()}
      title={label}
      variant={variant || 'primary'}
    >
      {renderOptions()}
    </DropdownButton>
  );
};
