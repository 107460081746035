import React from 'react';
import Badge from 'react-bootstrap/Badge';

export const FlashDealStatusPill = ({ status }) => {
  let styleName;

  if (!status) {
    return null;
  }

  switch (status) {
    case 'approved':
      styleName = 'success';
      break;
    case 'rejected':
      styleName = 'danger';
      break;
    case 'appealed':
      styleName = 'info';
      break;
    case 'submitted':
      styleName = 'warning';
      break;
    case 'cancelled':
      styleName = 'secondary';
      break;
    case 'completed':
      styleName = 'secondary';
      break;
  }

  return <Badge pill bg={styleName}>{status.toUpperCase()}</Badge>;
};
