import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

// Bootstrap
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import Table from 'react-bootstrap/Table';

import { DropDownFilter } from '../../../lib/components/DropDownFilter';
import { Pagination } from '../../../lib/components/Pagination';

import { loadListings, updateSingleField } from '../store/actions';
import {
  selectListing,
  setAppFilter,
  setIncUnpublishedFilter
} from '../store/slice';

import { useIsAdmin } from '../../../lib/hooks/useIsAdmin';
import { devLog } from '../../../lib/util/devLog';
import { getS3ImageName } from '../../../lib/util/images';

import { listingsPerPage } from '../../../../common/config';
import { FauxCheckBox } from '../../../lib/components/FauxCheckBox';

export const List = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const isAdmin = useIsAdmin();

  const page = searchParams.get('page') || 0;

  const [showNoListings, setShowNoListings] = useState(false);

  const access = useSelector(({ auth }) => auth.access);
  const isLoggedIn = useSelector(({ auth }) => auth.isLoggedIn);
  const appsForFilter = useSelector(
    ({ explore }) => explore.appsForFilter
  );
  const listings = useSelector(({ explore }) => explore.listings);
  const incUnpublishedFilter =
    useSelector(({ explore }) => explore.incUnpublishedFilter);
  const isLoadingListings =
    useSelector(({ explore }) => explore.isLoadingListings);
  const numTotal = useSelector(({ explore }) => explore.numTotal);
  const perPage = useSelector(({ explore }) => explore.perPage);

  // Shared load function
  const _loadListings = ({ page }) => {
    return dispatch(loadListings({ page }))
      .unwrap()
      .catch(err => {
        devLog('UE LIST LOAD ERROR', err);
        if (err && err?.code === 401) {
          setShowNoListings(true);
        }
      });
  };

  // Redirect if not logged in
  useEffect(() => {
    if (!isLoggedIn) {
      return navigate('/login', { replace: true });
    }
  }, [isLoggedIn]);

  // Redirect if shouldn't be here
  useEffect(() => {
    if ((access && access.manageExplore !== true) && isAdmin.any !== true) {
      return navigate('/', { replace: true });
    }
  });

  // Loading listings if not already loading or found has no listings to show
  useEffect(() => {
    // console.log('UE[page], access, isLoadingListings, showNoListings, isAdmin', access, isLoadingListings, showNoListings, isAdmin);
    if (
      ((access && access.manageExplore === true) || isAdmin.any === true) &&
      !isLoadingListings && !showNoListings
    ) {
      _loadListings({ page });
    }
  }, [page]);

  // Event handlers

  const onClickSelectListing = id => {
    dispatch(selectListing({ id }));
  }

  const onChangeAppFilter = (id, appFilterState) => {
    dispatch(setAppFilter({ id, appFilterState }));
    if (parseInt(page) === 0) {
      _loadListings({ page: 0 });
    }
    else {
      navigate('/manage-explore?page=0');
    }
  };

  const onChangeIncUnpublishedFilter = () => {
    dispatch(setIncUnpublishedFilter(
      { incUnpublishedFilterState: !incUnpublishedFilter }
    ));
    if (parseInt(page) === 0) {
      _loadListings({ page: 0 });
    }
    else {
      navigate('/manage-explore?page=0');
    }
  };

  const onClickFeaturedOnLatest = id => {
    if (window.confirm(
      'Are you sure you want to make this the Featured Offer?' +
      '\n\nIf another listing is already featured it will be unfeatured ' +
      'automatically.\n\nThis will happen immediately when you click OK.'
    )) {
      return dispatch(updateSingleField({
        id,
        field: 'featuredOnLatest',
        value: true
      }))
        .unwrap()
        .catch(err => {
          devLog('FEATURED ERROR', err);
        });
    }
  };

  const onClickUnfeaturedOnLatest = id => {
    return dispatch(updateSingleField({
      id,
      field: 'featuredOnLatest',
      value: false
    }))
      .unwrap()
      .catch(err => {
        devLog('UNFEATURED ERROR', err);
      });
  };

  const onClickShowOnLatest = id => {
    return dispatch(updateSingleField({
      id,
      field: 'showOnLatest',
      value: true
    }))
      .unwrap()
      .catch(err => {
        devLog('SHOW ERROR', err);
      });
  };

  const onClickUnshowOnLatest = (id, featuredOnLatest = false) => {
    function dispatchUpdate() {
      return dispatch(updateSingleField({
        id,
        field: 'showOnLatest',
        value: false
      }))
        .unwrap()
        .catch(err => {
          devLog('UNSHOW ERROR', err);
        });
    }

    if (!featuredOnLatest) {
      dispatchUpdate();
    }
    else {
      if (window.confirm(
        'This listing is the current Featured Offer. If you "unshow" it from ' +
        'the Latest screen, it will also be unfeatured.' +
        '\n\nThis will happen immediately when you click OK. Click Cancel to ' +
        'leave things unchanged.'
      )) {
        return dispatchUpdate();
      }
    }
  };

  // / Event handlers

  // Fragment render fns

  const renderAppFilter = () => {
    return <DropDownFilter
      label="Apps"
      onChange={onChangeAppFilter}
      options={appsForFilter}
    />;
  };

  const renderIncUnpublishedFilter = () => {
    return (
      <div onClick={onChangeIncUnpublishedFilter} role="button">
        <FauxCheckBox
          active={incUnpublishedFilter}
          label="Include unpublished"
        />
      </div>
    );
  };

  const renderAppsSummary = apps => {
    let appsStr = 'None Selected';
    if (apps.length > 0) {
      appsStr = apps.filter(a => a.active).map(a => a.label).join(' / ');
    }
    return (<div>
      <span className="fw-bold text-white-50">Apps:</span> {appsStr}
    </div>);
  };

  const renderImage = (image, isPublished) => {
    if (image) {
      return <img
        className={`list-image-square opacity-${isPublished === true ? 100 : 50}`}
        crossOrigin="anonymous"
        src={getS3ImageName(image, 384)}
      />;
    }

    return <div className="list-missing-image-square"></div>;
  };

  const renderListingName = (name, isPublished) => {
    if (isPublished) {
      return <span className="fw-bold">{name}</span>;
    }

    return <span className="text-muted">
      <span className="fw-bold">{name}</span>{'  '}
      <span className="fs-7 fst-italic">[UNPUBLISHED]</span>
    </span>;
  };

  const renderFeaturedOnLatestButton = id => {
    return <Button
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        onClickFeaturedOnLatest(id);
      }}
      variant="success">
      <i className="bi-plus-lg" /> Feature
    </Button >;
  };

  const renderUnfeaturedOnLatestButton = id => {
    return <Button
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        onClickUnfeaturedOnLatest(id);
      }}
      variant="danger">
      <i className="bi-dash-lg" /> Feature
    </Button >;
  };

  const renderShowOnLatestButton = id => {
    return <Button
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        onClickShowOnLatest(id);
      }}
      variant="success">
      <i className="bi-plus-lg" /> Latest
    </Button >;
  };

  const renderUnshowOnLatestButton = (id, featuredOnLatest = false) => {
    return <Button
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        onClickUnshowOnLatest(id, featuredOnLatest);
      }}
      variant="danger">
      <i className="bi-dash-lg" /> Latest
    </Button >;
  };

  const renderIsMissingText = missingFeaturedText => {
    return missingFeaturedText ?
      (<span className="fs-7 text-muted">
        <i className="bi-info-circle-fill" /> Missing "Featured" text
      </span>) :
      null;
  };

  // / Fragment render fns

  return (
    <>
      <div className="d-flex align-items-start justify-content-between">
        <h1 className="mb-4">Manage Explore Listings</h1>

        {/* <Button onClick={() => {}} variant="primary">
          <i className="bi-plus-lg" /> Add Explore Listing
        </Button> */}
      </div>

      <Stack className="mb-4" direction="horizontal" gap={3}>
        {renderAppFilter()}
        {renderIncUnpublishedFilter()}
      </Stack>

      <div className="bg-secondary d-flex flex-row fs-7 justify-content-between mb-1 p-3 text-light">
        {renderAppsSummary(appsForFilter)}
      </div>

      {showNoListings && <Alert variant="info">
        No listings to edit.
      </Alert>}

      {!isLoadingListings && listings && <Table borderless striped hover>
        <tbody>
          {listings.map(listing => (
            <tr key={`lstg_list_${listing.id}`}>
              <td className="p-0">
                <Link
                  className="d-flex flex-row align-items-center justify-content-between p-1 text-decoration-none text-body"
                  to={`/manage-explore/${listing.id}`}
                  onClick={() => onClickSelectListing(listing.id)}
                >
                  {renderImage(listing.image, listing.isPublished)}

                  <Stack
                    className="flex-grow-1 p-2 px-3"
                    direction="horizontal"
                    gap={2}
                  >
                    <Stack className="justify-content-center" gap={1}>
                      {renderListingName(listing.name, listing.isPublished)}

                      {renderIsMissingText(listing.missingFeaturedText)}
                    </Stack>

                    {listing?.featuredOnLatest &&
                      renderUnfeaturedOnLatestButton(listing.id)}
                    {!listing?.featuredOnLatest && listing?.showOnLatest &&
                      renderFeaturedOnLatestButton(listing.id)}
                    {listing?.showOnLatest &&
                      renderUnshowOnLatestButton(
                        listing.id, listing?.featuredOnLatest
                      )}
                    {!listing.showOnLatest &&
                      renderShowOnLatestButton(listing.id)}

                    <Button variant="primary">Edit</Button>
                  </Stack>
                </Link>

              </td>
            </tr>
          ))}
        </tbody>
      </Table>}

      <div className="d-flex justify-content-center">
        <Pagination
          baseUrl="/manage-explore"
          maxLinksPerSide="3"
          numTotal={numTotal}
          page={page}
          perPage={listingsPerPage}
        />
      </div>
    </>
  );
};
