'use strict';

import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiGet, apiPost } from '../../../lib/util/api';

import { enquiriesPerPage } from '../../../../common/config';

// Load a page of enquiries
export const loadEnquiries = createAsyncThunk(
  'enquiries/loadPage',
  async (opts = {}, { getState, rejectWithValue }) => {
    let { page = 0 } = opts;

    try {
      // Get filters
      const { appsForFilter, statusesForFilter } = getState().enquiries;
      const selectedApps = appsForFilter
        .filter(app => app.active === true);

      // Fail out if there are no apps to select - ie. no access to apps
      if (appsForFilter.length < 1) {
        throw { code: 401 };
      }

      // Fail if no apps are selected
      if (selectedApps.length < 1) {
        throw ({
          data: [],
          meta: {}
        });
      }

      // Build request params      
      const params = {
        appIds: selectedApps.map(app => app.id).join(','),
        page,
        perPage: enquiriesPerPage,
        statuses: statusesForFilter
          .filter(status => status.active === true)
          .map(status => status.id)
          .join(',')
      };

      // Send the request
      const response = await apiGet('/cp-api/enquiries', { params });

      return response.data;
    }
    catch (err) {
      return rejectWithValue(err);
    }
  }
);

// Load a selected enquiry
export const loadEnquiry = createAsyncThunk(
  'enquiries/loadSelected',
  async (opts = {}, { getState, requestId, rejectWithValue }) => {
    let { id } = opts;

    try {
      // Send the request
      const response = await apiGet('/cp-api/enquiries/' + id);
      return response.data;
    }
    catch (err) {
      throw rejectWithValue(err);
    }
  }
);

// Update an enquiry's status
export const updateEnquiryStatus = createAsyncThunk(
  'enquiries/updateStatus',
  async (opts = {}, { getState, requestId, rejectWithValue }) => {
    let { id, status } = opts;

    try {
      // Send the request
      const response = await apiPost(
        `/cp-api/enquiries/${id}/status`,
        { data: { status } }
      );

      return response.data;
    }
    catch (err) {
      throw rejectWithValue(err);
    }
  }
);
