'use strict';

import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiGet, apiPost } from '../../../../lib/util/api';

// Load an enquiry's responses
export const loadResponses = createAsyncThunk(
  'responses/load',
  async (opts = {}, { getState, requestId, rejectWithValue }) => {
    let { id } = opts;

    try {
      // Send the request
      const response = await apiGet(`/cp-api/enquiries/${id}/responses`);
      return response.data;
    }
    catch (err) {
      throw rejectWithValue(err);
    }
  }
);

// Submit response
export const submitResponse = createAsyncThunk(
  'responses/submit',
  async (opts = {}, { getState, requestId, rejectWithValue }) => {
    const { body, id } = opts;

    const { currentRequestId, isSubmitting } = getState().responses;
    if (!isSubmitting || requestId !== currentRequestId) {
      return;
    }

    try {
      const response = await apiPost(
        `/cp-api/enquiries/${id}/responses`,
        { data: { body } }
      );

      return response.data;
    }
    catch (err) {
      throw rejectWithValue(err);
    }
  }
)
