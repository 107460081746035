import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

// Bootstrap
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import Table from 'react-bootstrap/Table';

import { EmailSearchField } from '../components/EmailSearchField';
import { LoginListAccessSettings } from '../components/LoginListAccessSettings';
import { LoginListAppNames } from '../components/LoginListAppNames';
import { LoginListExploreNames } from '../components/LoginListExploreNames';
import { DropDownFilter } from '../../../lib/components/DropDownFilter';
import { Pagination } from '../../../lib/components/Pagination';

import { loadLogins } from '../store/actions';
import { selectLogin, setAppFilter } from '../store/slice';

import { useIsAdmin } from '../../../lib/hooks/useIsAdmin';

import { loginsPerPage } from '../../../../common/config';

export const List = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const isAdmin = useIsAdmin();

  const page = searchParams.get('page') || 0;

  const [showNoLogins, setShowNoLogins] = useState(false);

  const access = useSelector(({ auth }) => auth.access);
  const isLoggedIn = useSelector(({ auth }) => auth.isLoggedIn);
  const appsForFilter = useSelector(
    ({ manageLogins }) => manageLogins.appsForFilter
  );
  const logins = useSelector(({ manageLogins }) => manageLogins.logins);
  const isLoadingLogins =
    useSelector(({ manageLogins }) => manageLogins.isLoadingLogins);
  const numTotal = useSelector(({ manageLogins }) => manageLogins.numTotal);
  const perPage = useSelector(({ manageLogins }) => manageLogins.perPage);

  // Shared load function
  const _loadLogins = ({ page }) => {
    return dispatch(loadLogins({ page }))
      .unwrap()
      .catch(err => {
        // console.log('UE LIST LOAD ERROR', err);
        if (err && err?.code === 401) {
          setShowNoLogins(true);
        }
      });
  };

  // TODO active login filter/indication on list

  // Redirect if not logged in
  useEffect(() => {
    if (!isLoggedIn) {
      return navigate('/login', { replace: true });
    }
  }, [isLoggedIn]);

  // Redirect if shouldn't be here
  useEffect(() => {
    if ((access && access.manageLogins !== true) && isAdmin.any !== true) {
      return navigate('/', { replace: true });
    }
  });

  // Loading listings if not already loading or found has no listings to show
  useEffect(() => {
    // console.log('UE[page], access, isLoadingLogins, showNoLogins, isAdmin', access, isLoadingLogins, showNoLogins, isAdmin);
    if (
      ((access && access.manageLogins === true) || isAdmin.any === true) &&
      !isLoadingLogins && !showNoLogins
    ) {
      _loadLogins({ page });
    }
  }, [page]);

  // Event handlers

  const onChangeAppFilter = (id, appFilterState) => {
    dispatch(setAppFilter({ id, appFilterState }));
    _loadLogins({ page: 0 });
  };

  const onClickSelectLogin = id => {
    dispatch(selectLogin({ id }));
  }

  const onClickAddLogin = id => {
    return navigate('/manage-logins/add');
  }

  // / Event handlers

  // Fragment render fns

  const renderAppFilter = () => {
    return <DropDownFilter
      label="Apps"
      onChange={onChangeAppFilter}
      options={appsForFilter}
    />;
  };

  const renderEmailSearch = () => {
    return <EmailSearchField
      onClickSearch={() => { console.log('SEARH!'); }}
    />;
  };

  const renderAppsSummary = apps => {
    let appsStr = 'None Selected';
    if (apps.length > 0) {
      appsStr = apps.filter(a => a.active).map(a => a.label).join(' / ');
    }
    return (<div>
      <span className="fw-bold text-white-50">Apps:</span> {appsStr}
    </div>);
  };

  const renderAccess = access => {
    return (
      <>
        <span>lots of access</span>
      </>
    )
  };

  const renderAppNames = access => {
    return (
      <>
        <span>lots of apps</span>
      </>
    )
  };

  // / Fragment render fns

  return (
    <>
      <div className="d-flex align-items-start justify-content-between">
        <h1 className="mb-4">Manage Logins</h1>

        <Button onClick={onClickAddLogin} variant="primary">
          <i className="bi-plus-lg" /> Add Login
        </Button>
      </div>

      <Stack className="mb-4" direction="horizontal" gap={3}>
        {renderAppFilter()}
        {/* {renderEmailSearch()} */}
      </Stack>

      <div className="bg-secondary d-flex flex-row fs-7 justify-content-between mb-1 p-3 text-light">
        {renderAppsSummary(appsForFilter)}
      </div>

      {showNoLogins && <Alert variant="info">
        No logins to edit.
      </Alert>}

      {!isLoadingLogins && logins && <Table borderless striped hover>
        <tbody>
          {logins.map(login =>
            <tr key={`lgns_list_${login.id}`}>
              <td className="p-0">
                <Link
                  className="d-flex flex-row align-items-center justify-content-between p-3 text-decoration-none text-body"
                  to={`/manage-logins/${login.id}`}
                  onClick={() => onClickSelectLogin(login.id)}
                >
                  
                    <Stack className="flex-grow-1" gap={1}>
                      <span className="fw-bold">
                        {login.name}
                      </span>
                      <Stack className="fs-7" direction="horizontal" gap={2}>
                        {login.exploreIds && <span className="text-muted mr-2">
                          <i className="bi-geo-alt" />{' '}
                          <LoginListExploreNames
                            exploreNames={login.exploreNames}
                          />
                        </span>}  {login.appIds &&
                          <span className="text-muted">
                            <i className="bi-phone" />{' '}
                            <LoginListAppNames
                              appIds={login.appIds}
                              apps={appsForFilter}
                            />
                          </span>}
                      </Stack>
                      <span className="fs-7 text-muted">
                        <LoginListAccessSettings access={login.access} />
                      </span>
                    </Stack>

                    <Button variant="primary">Edit</Button>

                </Link>
              </td>
            </tr>
          )}
        </tbody>
      </Table>}

      <div className="d-flex justify-content-center">
        <Pagination
          baseUrl="/manage-logins"
          maxLinksPerSide="3"
          numTotal={numTotal}
          page={page}
          perPage={loginsPerPage}
        />
      </div>
    </>
  );
};
