import React from 'react';

export const LoginListExploreNames = ({ exploreNames }) => {
  if (exploreNames.length < 1) {
    return null;
  }

  return (
    <span>
      {exploreNames.reduce((prev, curr) => [prev, ', ', curr])}
    </span>
  );
};
