'use strict';

const monthNames = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];

const getOrdinal = date => {
  switch (date) {
    case 1: return 'st';
    case 2: return 'nd';
    case 3: return 'rd';
    default: return 'th';
  }
};

const getMeridiem = hour => {
  return hour > 11 ? 'pm' : 'am';
};

export function friendlyDate(date) {
  if (typeof date !== 'object') {
    date = new Date(date);
  }

  const m = monthNames[date.getMonth()];
  const d = date.getDate();
  const ord = getOrdinal(d);
  const y = date.getFullYear();
  let h = date.getHours();
  const mer = getMeridiem(h);
  h = h > 12 ? h - 12 : h;
  const n = (`0${date.getMinutes()}`).slice(-2);

  return `${m} ${d}${ord} ${y}, ${h}:${n}${mer}`;
}
