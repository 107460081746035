'use strict';

import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { isSuperAdmin } from 'spontly-mongoose-models/models/CPLogin/schema';

export const useIsAdmin = () => {
  const isLoggedIn = useSelector(({ auth }) => auth.isLoggedIn);
  const isLoggingIn = useSelector(({ auth }) => auth.isLoggingIn);
  const isTWMEAdmin = useSelector(({ auth }) => auth.isTWMEAdmin);
  const isSuperAdmin = useSelector(({ auth }) => auth.isSuperAdmin);

  const updateIsAdmin = (isTWMEAdmin, isSuperAdmin) => {
    return {
      TWME: isTWMEAdmin || false,
      super: isSuperAdmin || false,
      any: (isTWMEAdmin || isSuperAdmin) || false
    };
  };

  const isAdmin = useRef(updateIsAdmin(isTWMEAdmin, isSuperAdmin));

  useEffect(() => {
    if (!isLoggingIn) {
      isAdmin.current = updateIsAdmin(isTWMEAdmin, isSuperAdmin)
    }
  }, [isLoggedIn]);

  return isAdmin.current;
};
