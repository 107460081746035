import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// Bootstrap
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Stack from 'react-bootstrap/Stack';
import Table from 'react-bootstrap/Table';

export const List = () => {
  const access = useSelector(({ auth }) => auth.access);
  const allowedApps = useSelector(({ auth }) => auth.allowedApps);

  return (
    <>
      <h1 className="mb-4">Apps</h1>

      <Table borderless striped hover>
        <tbody>
          {allowedApps.map(app =>
            <tr key={`apps_list_${app.appId}`}>
              <td className="p-3">
                <div className="fw-bold text-body">{app.name}</div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};
