import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

// Bootstrap
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import Table from 'react-bootstrap/Table';

import { loadListings } from '../store/actions';
import { logOut } from '../../auth/store/actions';
import { selectListing, selectFlashDeal } from '../store/slice';

export const List = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showNoListings, setShowNoListings] = useState(false);

  const access = useSelector(({ auth }) => auth.access);
  const isLoggedIn = useSelector(({ auth }) => auth.isLoggedIn);
  const listings = useSelector(({ myListings }) => myListings.listings);
  // const listingsFlashDeals = useSelector(({ myListings }) =>
  //   myListings.listingsFlashDeals);
  const isLoadingListings =
    useSelector(({ myListings }) => myListings.isLoadingListings);

  // Redirect if not logged in
  useEffect(() => {
    if (!isLoggedIn) {
      return navigate('/login', { replace: true });
    }
  }, [isLoggedIn]);

  // Redirect if shouldn't be here
  useEffect(() => {
    if (access && access.editListings !== true) {
      return navigate('/', { replace: true });
    }
  });

  // Loading listings if not already loading or found has no listings to show
  useEffect(() => {
    if (!isLoadingListings && !showNoListings) {
      dispatch(loadListings())
        .unwrap()
        .catch(err => {
          if (err && err?.code === 401) {
            setShowNoListings(true);
          }
        });
    }
  }, []);

  // Event handlers

  const onClickSelectListing = id => {
    dispatch(selectListing({ id }));
  };

  const onClickSetUpFlashDeal = (appId, id, name) => {
    dispatch(selectFlashDeal({ appId, id, name }));
    return navigate('/my-listings/flash-deals/set-up');
  };

  const onClickEditFlashDeal = (appId, id, name, flashDealId) => {
    dispatch(selectFlashDeal({ appId, id, name, flashDealId }));
    return navigate(`/my-listings/flash-deals/${flashDealId}`);
  };

  // / Event handlers

  // Fragment render fns

  const renderFlashDealButton = (appId, id, name, flashDealId) => {
    return !flashDealId ?
      <Button
        className="ms-2"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          onClickSetUpFlashDeal(appId, id, name);
        }}
        variant="success">
        <i className="bi-lightning-fill" /> Set Up Flash Deal
      </Button > :
      <Button
        className="ms-2"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          onClickEditFlashDeal(appId, id, name, flashDealId);
        }}
        variant="primary">
        <i className="bi-lightning-fill" /> Edit Flash Deal
      </Button >;
  };

  // / Fragment render fns


  return (
    <>
      <h1 className="mb-4">My Listings</h1>

      {showNoListings && <Alert variant="info">
        You have no listings to edit. If this is incorrect please contact us at
        contact@theworldmadeeasy.com
      </Alert>}

      {!isLoadingListings && listings && <Table borderless striped hover>
        <tbody>
          {listings.map(listing =>
            <tr key={`lstngs_list_${listing.id}`}>
              <td className="p-0">
                <Link
                  className="d-flex flex-row align-items-center justify-content-between p-3 text-decoration-none text-body"
                  to={`/my-listings/${listing.id}`}
                  onClick={() => onClickSelectListing(listing.id)}
                >
                  <div className="fw-bold text-body flex-grow-1">{listing.name}</div>

                  {access.flashDeals && renderFlashDealButton(
                    listing.appId,
                    listing.id,
                    listing.name,
                    listing.flashDealId || null
                  )}
                  <Button className="ms-2" variant="primary">Edit</Button>
                </Link>
              </td>
            </tr>
          )}
        </tbody>
      </Table>}
    </>
  );
};
