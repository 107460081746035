import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

// Bootstrap
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import Table from 'react-bootstrap/Table';

import { friendlyDate } from '../../../lib/util/dates';
import { FlashDealStatusPill } from '../components/FlashDealStatusPill';

import { loadFlashDealsList } from '../store/actions';
import { logOut } from '../../auth/store/actions';
import { selectListing, selectFlashDeal } from '../store/slice';

export const FlashDeals = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showNoListings, setShowNoListings] = useState(false);

  const access = useSelector(({ auth }) => auth.access);
  const isLoggedIn = useSelector(({ auth }) => auth.isLoggedIn);
  const flashDealsList = useSelector(({ myListings }) => myListings.flashDealsList);
  // const listingsFlashDeals = useSelector(({ myListings }) =>
  //   myListings.listingsFlashDeals);
  const isLoadingListings =
    useSelector(({ myListings }) => myListings.isLoadingListings);

  // Redirect if not logged in
  useEffect(() => {
    if (!isLoggedIn) {
      return navigate('/login', { replace: true });
    }
  }, [isLoggedIn]);

  // Redirect if shouldn't be here
  useEffect(() => {
    if (access && access.flashDeals !== true) {
      return navigate('/', { replace: true });
    }
  });

  // Loading FD listings if not already loading or found has no listings to show
  useEffect(() => {
    if (!isLoadingListings && !showNoListings) {
      dispatch(loadFlashDealsList())
        .unwrap()
        .catch(err => {
          // console.log(err);
          if (err && (err?.code === 401 || err?.code === 204)) {
            setShowNoListings(true);
          }
        });
    }
  }, []);

  // Event handlers

  const onClickSelectListing = id => {
    dispatch(selectListing({ id }));
  };

  const onClickEditFlashDeal = (appId, id, name, flashDealId) => {
    dispatch(selectFlashDeal({ appId, id, name, flashDealId }));
    return navigate(`/my-listings/flash-deals/${flashDealId}`);
  };

  // / Event handlers

  return (
    <>
      <h1 className="mb-4">Flash Deals</h1>

      {showNoListings && <Alert variant="info">
        You have no Flash Deals to edit. If this is incorrect please contact us
        at contact@theworldmadeeasy.com
      </Alert>}

      {!isLoadingListings && flashDealsList && <Table borderless striped hover>
        <tbody>
          {flashDealsList.map(flashDeal =>
            <tr key={`flsdls_list_${flashDeal.id}`}>
              <td className="p-0">
                <Link
                  className="d-block p-3 text-decoration-none"
                  to={`/my-listings/flash-deals/${flashDeal.id}`}
                  onClick={
                    () => onClickEditFlashDeal(
                      flashDeal.appId,
                      flashDeal.exploreId,
                      flashDeal.name,
                      flashDeal.id
                    )
                  }
                >
                  <Stack
                    className="align-items-center text-body"
                    direction="horizontal"
                    gap="3"
                  >
                    <Stack gap={2}>
                      <div className="fs-6">
                        <span className="fw-bold">
                          {flashDeal.name}
                        </span>: <span className="text-muted">
                          &quot;{flashDeal.summary}&quot;
                        </span>
                      </div>
                      <div className="fs-7 text-muted">
                        <span className="fw-bold">
                          {friendlyDate(flashDeal.from)}
                        </span> &gt; <span className="fw-bold">
                          {friendlyDate(flashDeal.to)}
                        </span>
                      </div>
                    </Stack>

                    <FlashDealStatusPill status={flashDeal.status} />

                    <Button className="ms-2" variant="primary">Edit</Button>
                  </Stack>
                </Link>
              </td>
            </tr>
          )}
        </tbody>
      </Table>}
    </>
  );
};
