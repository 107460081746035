'use strict';

import { createSlice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import { logIn, logOut } from '../../auth/store/actions';
import {
  addFormSearchExplore,
  getAddLoginFormDefaults,
  loadLogin,
  loadLogins,
  resetPassword,
  searchExplore,
  submitAddLogin,
  submitLogin
} from './actions';

import { loginsPerPage } from '../../../../common/config';

export const initialState = {
  accessForForm: {},
  addFormExploreItems: [],
  appsForFilter: [],
  isFetchingDefaults: false,
  isLoadingLogins: false,
  isLoadingSelected: false,
  logins: [],
  // loginTypesForForm: [],
  numTotal: 0,
  perPage: 0,
  selectedLogin: null,
  selectedLoginId: null,

  exploreSearchResults: [],

  // Stuff just for handling multiple request submission
  currentResetPasswordId: null,
  isResettingPassword: false,
  currentRequestId: null,
  isSubmitting: false,
  currentSearchId: null,
  isSearching: false
};

// Common log out callback
const commonLogOutCallback = (state, action) => {
  return { ...initialState };
};

// Created manageLogins store slice
const manageLoginsSlice = createSlice({
  name: 'manageLogins',

  initialState,

  reducers: {
    // Select login
    selectLogin: (state, action) => {
      // console.log('reducer selectLogin', action.payload);
      state.selectedLogin = null;
      state.selectedLoginId = action.payload.id;
    },

    // Update app filter
    setAppFilter: (state, action) => {
      const { id, appFilterState } = action.payload;
      const thisApp = state.appsForFilter.find(a => a.id === id);
      if (thisApp) {
        thisApp.active = !!appFilterState;
        state.appsForFilter = [...state.appsForFilter];
      }
    },

    // Clear search results
    clearExploreSearchResults: (state) => {
      state.exploreSearchResults = [];
    },

    // // Add Form add Explore search result
    // addAddFormExploreItem: (state, action) => {
    //   console.log('addAddFormExploreItem reducer payload', action.payload);
    //   const item = action.payload.item;
    //   // Check we don't add a dupe
    //   const exists = state.addFormExploreItems.find(
    //     eItem => eItem.id == item.id
    //   );
    //   if (!exists) {
    //     const { exploreItems } = state.addFormExploreItems;
    //     exploreItems.push(item);
    //     exploreItems.sort((a, b) => a.name.localeCompare(b.name));

    //     state.addFormExploreItems = exploreItems;
    //   }
    // },

    // // Remove an associate Explore item from the current login
    // removeAddFormExploreItem: (state, action) => {
    //   console.log('removeAddFormExplore reducer payload', action.payload);
    //   state.addFormExploreItems =
    //     state.addFormExploreItems.filter(
    //       item => item.id !== action.payload.id
    //     );
    // }

    // Add Explore search result to current login
    addExploreItem: (state, action) => {
      // console.log('addExploreItem reducer payload', action.payload);
      const item = action.payload.item;
      // Check we don't add a dupe
      const exists = state.selectedLogin.exploreItems.find(
        eItem => eItem.id == item.id
      );
      if (!exists) {
        const { exploreItems } = state.selectedLogin;
        exploreItems.push(item);
        exploreItems.sort((a, b) => a.name.localeCompare(b.name));

        state.selectedLogin.exploreItems = exploreItems;
      }
    },

    // Remove an associate Explore item from the current login
    removeExploreItem: (state, action) => {
      // console.log('removeExplore reducer payload', action.payload);
      state.selectedLogin.exploreItems =
        state.selectedLogin.exploreItems.filter(
          item => item.id !== action.payload.id
        );
    },

    // Set Access value
    setAccessValue: (state, action) => {
      // console.log('setAccessValue reducer payload', action.payload);
      const { key, value } = action.payload;
     
      const accessForForm = { ...state.accessForForm };
      accessForForm[key] = value;

      state.accessForForm = accessForForm;
    },

  },

  extraReducers: builder => {
    // Load logins PENDING
    builder.addCase(loadLogins.pending, state => {
      // console.log('REDUCED: loadLogins.pending');
      state.isLoadingLogins = true;
      state.selectedLoginId = null;
    });
    // Load logins FULFILLED
    builder.addCase(loadLogins.fulfilled, (state, action) => {
      // console.log('REDUCED: loadLogins.fulfilled');
      const { data = [], meta = {} } = action.payload;

      return {
        ...state,
        logins: data,
        isLoadingLogins: false,
        numTotal: meta.numTotal || 0,
        perPage: meta.perPage || loginsPerPage
      };
    });
    // Load logins REJECTED
    builder.addCase(loadLogins.rejected, state => {
      // console.log('REDUCED: loadLogins.rejected');
      return {
        ...state,
        logins: [],
        isLoadingLogins: false,
        numTotal: 0,
        perPage: loginsPerPage
      };
    });

    // Load selected login PENDING
    builder.addCase(getAddLoginFormDefaults.pending, state => {
      return {
        ...state,
        addFormExploreItems: [],
        isFetchingDefaults: true,
        selectedLoginId: null,
        selectedLogin: null
      };
    });
    // Load selected login FULFILLED
    builder.addCase(getAddLoginFormDefaults.fulfilled, (state, action) => {
      const { data = null } = action.payload;

      return {
        ...state,
        isFetchingDefaults: false,
        // loginTypesForForm: data?.loginTypes || []
      };
    });
    // Load selected login REJECTED
    builder.addCase(getAddLoginFormDefaults.rejected, state => {
      return {
        ...state,
        isFetchingDefaults: false
      };
    });

    // Load selected login PENDING
    builder.addCase(loadLogin.pending, state => {
      state.isLoadingSelected = true;
      state.selectedLogin = null;
    });
    // Load selected login FULFILLED
    builder.addCase(loadLogin.fulfilled, (state, action) => {
      const { data = null } = action.payload;

      // Add the app name to the search results
      const apps = state.appsForFilter;
      const exploreItems = [];

      data.login.exploreItems.forEach(exploreItem => {
        const app = apps.find(app => app.id === exploreItem.appId);

        if (app) {
          exploreItem.appName = app.label;
          delete exploreItem.appId;
        }
      });

      return {
        ...state,
        accessForForm: data?.login?.access || {},
        isLoadingSelected: false,
        selectedLogin: data.login
        // loginTypesForForm: data.loginTypes
      };
    });
    // Load selected login REJECTED
    builder.addCase(loadLogin.rejected, state => {
      return {
        ...state,
        selectedLogin: null,
        isLoadingSelected: false
      };
    });

    // Add Form search explore PENDING
    builder.addCase(addFormSearchExplore.pending, (state, action) => {
      // console.log('PENDING', state.isSearching, action.meta.requestId, state.currentSearchId);
      if (!state.isSearching) {
        state.currentSearchId = action.meta.requestId;
        state.exploreSearchResults = [];
        state.isSearching = true;
      }
    });
    // Add Form s explore FULFILLED
    builder.addCase(addFormSearchExplore.fulfilled, (state, action) => {
      // console.log('addFormSearchExplore fulfilled');
      if (
        state.isSearching &&
        state.currentSearchId === action.meta.requestId
      ) {
        // Add the app name to the search results
        const apps = state.appsForFilter;
        const exploreSearchResults = [...state.exploreSearchResults];

        // Loop each result, check we don't have it already, and give it appName
        action.payload.data.forEach(exploreItem => {
          const itemAppId = exploreItem.custIds[0];
          // Check if already have this
          const current = state.exploreSearchResults.find(
            item => item.id === exploreItem.id
          );

          // Don't already have it... continue
          if (!current) {
            delete exploreItem.custIds;

            // Get and assign the appName for display
            const app = apps.find(app => app.id === itemAppId);

            if (app) {
              exploreSearchResults.push({
                ...exploreItem,
                appName: app.label
              });
            }
          }
        });

        return {
          ...state,
          exploreSearchResults,

          // Resets
          currentSearchId: undefined,
          isSearching: false
        };
      }
    });
    // Add Form search Explore REJECTED
    builder.addCase(addFormSearchExplore.rejected, (state, action) => {
      // console.log('addFormSearchExplore rejected');
      if (
        state.isSearching &&
        state.currentSearchId === action.meta.requestId
      ) {
        state.currentSearchId = undefined;
        state.exploreSearchResults = [];
        state.isSearching = false;
      }
    });

    // Search explore PENDING
    builder.addCase(searchExplore.pending, (state, action) => {
      // console.log('PENDING', state.isSearching, action.meta.requestId, state.currentSearchId);
      if (!state.isSearching) {
        state.currentSearchId = action.meta.requestId;
        state.exploreSearchResults = [];
        state.isSearching = true;
      }
    });
    // Search explore FULFILLED
    builder.addCase(searchExplore.fulfilled, (state, action) => {
      if (
        state.isSearching &&
        state.currentSearchId === action.meta.requestId
      ) {
        // Add the app name to the search results
        const apps = state.appsForFilter;
        const login = state.selectedLogin;
        const exploreSearchResults = [];

        // Loop each result, check we don't have it already, and give it appName
        action.payload.data.forEach(exploreItem => {
          const itemAppId = exploreItem.custIds[0];
          // Check if already have this
          const current = login.exploreItems.find(
            item => item.id === exploreItem.id
          );

          // Don't already have it... continue
          if (!current) {
            delete exploreItem.custIds;

            // Get and assign the appName for display
            const app = apps.find(app => app.id === itemAppId);

            if (app) {
              exploreSearchResults.push({
                ...exploreItem,
                appName: app.label
              });
            }
          }
        });

        return {
          ...state,
          exploreSearchResults,

          // Resets
          currentSearchId: undefined,
          isSearching: false
        };
      }
    });
    // Search Explore REJECTED
    builder.addCase(searchExplore.rejected, (state, action) => {
      if (
        state.isSearching &&
        state.currentSearchId === action.meta.requestId
      ) {
        state.currentSearchId = undefined;
        state.exploreSearchResults = [];
        state.isSearching = false;
      }
    });

    // Submit add login PENDING
    builder.addCase(submitAddLogin.pending, (state, action) => {
      // console.log('SUB PEND');
      if (!state.isSubmitting) {
        state.currentRequestId = action.meta.requestId;
        state.isSubmitting = true;
      }
    });
    // Submit add login FULFILLED
    builder.addCase(submitAddLogin.fulfilled, (state, action) => {
      // console.log('SUB DONE', action.payload.data);

      if (
        state.isSubmitting &&
        state.currentRequestId === action.meta.requestId
      ) {
        const { data } = action.payload;
        return {
          ...state,
          selectedLoginId: data.id,
          selectedLogin: data,

          // Resets
          currentRequestId: undefined,
          isSubmitting: false
        };
      }
    });
    // Submit add login REJECTED
    builder.addCase(submitAddLogin.rejected, (state, action) => {
      // console.log('SUB FAIL');
      if (
        state.isSubmitting &&
        state.currentRequestId === action.meta.requestId
      ) {
        state.isSubmitting = false;
        state.currentRequestId = undefined;
      }
    });

    // Submit login PENDING
    builder.addCase(submitLogin.pending, (state, action) => {
      // console.log('SUB PEND');
      if (!state.isSubmitting) {
        state.currentRequestId = action.meta.requestId;
        state.isSubmitting = true;
      }
    });
    // Submit login FULFILLED
    builder.addCase(submitLogin.fulfilled, (state, action) => {
      // console.log('SUB DONE', action.payload.data);
      if (
        state.isSubmitting &&
        state.currentRequestId === action.meta.requestId
      ) {
        return {
          ...state,
          // selectedLogin: action.payload.data,

          // Resets
          currentRequestId: undefined,
          isSubmitting: false
        };
      }
    });
    // Submit login REJECTED
    builder.addCase(submitLogin.rejected, (state, action) => {
      // console.log('SUB FAIL');
      if (
        state.isSubmitting &&
        state.currentRequestId === action.meta.requestId
      ) {
        state.isSubmitting = false;
        state.currentRequestId = undefined;
      }
    });

    // Reset password PENDING
    builder.addCase(resetPassword.pending, (state, action) => {
      // console.log('RST PWD PEND');
      if (!state.isResettingPassword) {
        state.currentResetPasswordId = action.meta.requestId;
        state.isResettingPassword = true;
      }
    });
    // Reset password FULFILLED
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      // console.log('RST PWD DONE');
      if (
        state.isResettingPassword &&
        state.currentResetPasswordId === action.meta.requestId
      ) {
        state.currentResetPasswordId = undefined;
        state.isResettingPassword = false;
      }
    });
    // Reset password REJECTED
    builder.addCase(resetPassword.rejected, (state, action) => {
      // console.log('RST PWD FAIL');
      if (
        state.isResettingPassword &&
        state.currentResetPasswordId === action.meta.requestId
      ) {
        state.currentResetPasswordId = undefined;
        state.isResettingPassword = false;
      }
    });

    // On log in FULFILLED
    builder.addCase(logIn.fulfilled, (state, action) => {
      state.appsForFilter = action.payload.data.allowedApps.map(app => {
        return {
          active: true,
          id: app.appId,
          label: app.name
        };
      });
    });

    // Log out fulfilled or rejected due to error - reset state
    builder.addCase(logOut.fulfilled, commonLogOutCallback);
    builder.addCase(logOut.rejected, commonLogOutCallback);
  }
});

export const whitelist = [
  'appsForFilter',
  // 'loginTypesForForm',
  'numTotal',
  'perPage',
  'selectedLoginId'
];

export const {
  addAddFormExploreItem,
  removeAddFormExploreItem,
  addExploreItem,
  removeExploreItem,
  clearExploreSearchResults,
  selectLogin,
  setAccessValue,
  setAppFilter
} = manageLoginsSlice.actions;
export const reducer = manageLoginsSlice.reducer;
