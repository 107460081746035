import React from 'react';
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

export const EmailSearchField = () => {
  const dispatch = useDispatch();

  const {
    clearErrors,
    control,
    handleSubmit,
    reset,
    setError,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: { email: '' }
  });

  // With have onSubmit and onSubmitForm below to allow manual clearErrors()
  // as react-hook-form requires manual clearing for general form errors.
  const onSubmit = data => {
    return dispatch(searchLoginsByEmail(data))
      .unwrap()
      .catch(err => {
        setError(
          err.meta && err.meta.field ? err.meta.field : 'form',
          {
            type: 'server',
            message: err.error
          }
        );
      });
  };
  const onSubmitForm = e => {
    clearErrors();
    return handleSubmit(onSubmit)(e);
  };

  // On clear form
  const onClickReset = () => {
    reset();
  };

  return (
    <Container className="mx-0 px-0">
      <Row className="mx-0 px-0">
        <Col className="px-0" md={5} lg={4}>

          <Form onSubmit={onSubmitForm}>

            <Form.Group>
              <InputGroup>

                <Controller
                  defaultValue=""
                  control={control}
                  name="email"
                  render={({
                    field: { onBlur, onChange, ref, value },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState
                  }) => (<Form.Control
                    aria-label="Email address"
                    isInvalid={error}
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder="Email address"
                    ref={ref}
                    type="email"
                    value={value}
                  />)}
                  rules={{
                    required: {
                      message: 'Please enter your login email address.',
                      value: true
                    }
                  }}
                />

                <Button type="submit" variant="primary">
                  <i className="bi-search" />
                </Button>

                <Button onClick={onClickReset} variant="secondary">
                  <i className="bi-x-lg" />
                </Button>
              </InputGroup>

              {errors.email && <Form.Control.Feedback type="invalid">
                {errors.email?.message}
              </Form.Control.Feedback>}
            </Form.Group>

          </Form>
        </Col>
      </Row>
    </Container>
  );
};
