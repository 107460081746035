import React from 'react';
import { Link } from "react-router-dom";

export const Users = () => {
  return (
    <>
      <h1>Users</h1>
      <Link to="/">Home</Link>
    </>
  );
};
